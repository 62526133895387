import React, { useEffect, useState } from 'react';

import { ThemeProvider, Grid, createTheme, Paper, CardMedia, Typography, Button } from '@mui/material';

import useServerRequest from '../../hooks/serverRequest';
import { ROUTES_LIST, API_ENDPOINTS_LIST } from '../../commonUtils/constants';

//import HeaderComponent from '../Components/HeaderComponent/HeaderComponent';

import archeryImage from '../../assets/archery-icon.png';
import commonStyles from '../../commonUtils/commonStyles';

import Main from '../../main/Main';

const theme = createTheme({
  palette: {
    secondary: {
      main: commonStyles.color.authError,
    },
    primary: {
      main: commonStyles.color.firmGrey,
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
});

//let paymentType = 1;
const paymentTypesAvailable = [
  { name: 'Club Pricing', description: 'Club annual membership for unlimited event listings', price: 99.99 },
  { name: 'Pro Shop Annual Membership', description: 'Annual membership for unlimited event posting', price: 149.99 },
];

const appId = 'sandbox-sq0idb-Tu12htKewD26ThY1xiBgkQ';
const locationId = 'LZH5C2F55RAN2';
const SQUARE_URL = 'https://sandbox.web.squarecdn.com/v1/square.js';

let card;
let paymentToken;

export default function PaymentScreen() {
  const setGlobalLoading = (val) => {
    Main.APP_PARAMS.setGlobalState((prevState) => ({
      ...prevState,
      globalLoading: val,
    }));
  };

  const [state, setState] = useState({
    squareCardInitialized: false,
    payButtonDisabled: true,
    venueId: null,
    type: null,
  });

  const { getRequest: uploadData } = useServerRequest(setGlobalLoading);

  async function initializeCard(payments) {
    const card = await payments.card();
    await card.attach('#card-container');
    return card;
  }

  // This function tokenizes a payment method.
  // The ‘error’ thrown from this async function denotes a failed tokenization,
  // which is due to buyer error (such as an expired card). It is up to the
  // developer to handle the error and provide the buyer the chance to fix
  // their mistakes.
  async function tokenize(paymentMethod) {
    const tokenResult = await paymentMethod.tokenize();
    if (tokenResult.status === 'OK') {
      return tokenResult.token;
    } else {
      let errorMessage = `Tokenization failed-status: ${tokenResult.status}`;
      if (tokenResult.errors) {
        errorMessage += ` and errors: ${JSON.stringify(tokenResult.errors)}`;
      }
      throw new Error(errorMessage);
    }
  }

  async function checkAndInitSquare(venueId, type) {
    if (!state.squareCardInitialized) {
      if (!window.Square) {
        throw new Error('Square.js failed to load properly');
      }
      const payments = window.Square.payments(appId, locationId);
      try {
        card = await initializeCard(payments);
      } catch (e) {
        console.error('Initializing Card failed', e);
        return;
      }
      //console.log('card.attached: ' + card.attached);
      setState((prevState) => ({
        ...prevState,
        squareCardInitialized: true,
        payButtonDisabled: false,
        venueId: venueId,
        type: type,
      }));
    }
  }

  useEffect(() => {
    //check if we have params
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const venueId = params.get('venueId');
    const type = params.get('pt');
    //console.log('venueId: ' + venueId);
    //attach external Square library
    const script = document.createElement('script');
    script.src = SQUARE_URL;
    script.async = true;
    script.onload = () => {
      if (type === '0' || type === '1') checkAndInitSquare(venueId, type);
    };
    document.body.appendChild(script);
  }, []);

  const createPayment = () => {
    const dataForServer = {
      venueId: state.venueId,
      token: paymentToken,
      locationId: locationId,
    };
    //console.log('data: ' + JSON.stringify(dataForServer));
    uploadData(API_ENDPOINTS_LIST.createVenuePayment, JSON.stringify(dataForServer)).then((res) => {
      if (res && res.status === 200) {
        Main.APP_PARAMS.setGlobalState((prevState) => ({
          ...prevState,
          toastOpen: true,
          toastMessage: `Payment made successful! Thank you!`,
          toastStyle: 'success',
        }));
      } else {
        Main.APP_PARAMS.setGlobalState((prevState) => ({
          ...prevState,
          toastOpen: true,
          toastMessage: `Error! Can't add data to server. Please try again or contact us!`,
          toastStyle: 'error',
        }));
      }
    });
  };

  //handlers
  const payButtonPressed = async () => {
    try {
      // disable the submit button as we await tokenization and make a
      // payment request.
      setState((prevState) => ({
        ...prevState,
        payButtonDisabled: true,
      }));
      paymentToken = await tokenize(card);
      //console.log('token: ' + JSON.stringify(paymentToken));
      createPayment();
      //const paymentResults = await createPayment(token);
      //displayPaymentResults('SUCCESS');

      //console.debug('Payment Success', paymentResults);
    } catch (e) {
      setState((prevState) => ({
        ...prevState,
        payButtonDisabled: false,
      }));
      //displayPaymentResults('FAILURE');
      //console.error(e.message);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      {/*<HeaderComponent screenNumber={6} />*/}
      <Paper style={getStyles().mainPaper}>
        <Grid container direction="row" style={getStyles().topTextGrid}>
          <Grid item xl={3} lg={3} md={3} sm={12} xs={12} style={getStyles().topTextGridColumn1}>
            <Grid item>
              <Typography noWrap style={commonStyles.text.robotoBold30}>
                Toxon App
              </Typography>
            </Grid>
            <Grid container direction="row" alignItems="center">
              <Grid item>
                <CardMedia component="img" image={archeryImage} style={getStyles().archeryImage} />
              </Grid>
              <Grid item>
                <Typography>Archery. Connected.</Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xl={9} lg={9} md={9} sm={12} xs={12} style={getStyles().topTextGridColumn2}>
            {state.type !== null ? (
              <>
                <Typography style={{ ...commonStyles.text.robotoRegular20bold, ...getStyles().textHeader }}>
                  {paymentTypesAvailable[state.type].name}
                </Typography>
                <Typography style={{ ...commonStyles.text.robotoRegular18, ...getStyles().textHeader }}>
                  ${paymentTypesAvailable[state.type].price}
                </Typography>
                <Typography style={getStyles().textBlock}>{paymentTypesAvailable[state.type].description}</Typography>
              </>
            ) : (
              <Typography style={{ ...commonStyles.text.robotoRegular18, ...getStyles().textHeader }}>
                Loading, please wait...
              </Typography>
            )}
            <form id="payment-form">
              <div style={{ maxWidth: '600px' }} id="card-container"></div>
              {state.type !== null ? (
                <Button
                  variant="contained"
                  color="primary"
                  disabled={state.payButtonDisabled}
                  style={{ color: commonStyles.color.white }}
                  onClick={() => {
                    payButtonPressed();
                  }}
                >
                  {state.type !== null ? 'Pay $' + paymentTypesAvailable[state.type].price : null}
                </Button>
              ) : null}
            </form>
            <Typography style={getStyles().textHeader}>
              * Payment solution provided by{' '}
              <a href="https://squareup.com/" target="_blank" rel="noreferrer">
                Square
              </a>
            </Typography>
            <Typography style={getStyles().textHeader}>
              ** If you have problems with payment, please get in touch using{' '}
              <a href="https://www.toxonapp.com/contact-us" target="_blank" rel="noreferrer">
                Contact Us
              </a>{' '}
              page and let us know your venue id:{' '}
              <span style={{ fontWeight: 'bold' }}>
                <span style={{ fontSize: '1.1rem' }}>{state.venueId}</span>
              </span>
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </ThemeProvider>
  );
}

const getStyles = () => {
  const styles = {
    mainPaper: { marginTop: '100px' },
    archeryImage: {
      maxWidth: '40px',
    },
    topTextGrid: {
      marginTop: '70px',
      minHeight: '100px',
      maxWidth: '100%',
      //background: '#FFAA00',
    },
    topTextGridColumn1: {
      //background: '#DDFF99',
      padding: '30px',
    },
    topTextGridColumn2: {
      //background: '#99FF99',
      padding: '30px',
      //userSelect: 'none',
    },
    textHeader: { marginTop: '30px', marginBottom: '30px' },
    textBlock: { paddingBottom: '15px' },
    redText: { color: '#FF0000' },
  };
  return styles;
};
