import React from 'react';
import {
  ThemeProvider,
  createTheme,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

import Main from '../../../main/Main';
import commonStyles from '../../../commonUtils/commonStyles';

const theme = createTheme({
  palette: {
    secondary: {
      main: commonStyles.color.blue,
    },
    primary: {
      main: commonStyles.color.firmGrey,
    },
    action: {
      disabledBackground: commonStyles.color.blueDisabled,
      disabled: commonStyles.color.white,
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
});

function DialogComponent(props) {
  const { dialogOpen = false, handleClose } = props;

  const handleSelfClose = () => {
    Main.APP_PARAMS.setGlobalState((prevState) => ({
      ...prevState,
      globalDialog: false,
      globalDialogData: {
        title: 'Title',
        message: 'Message',
        buttonOK: 'OK',
        buttonDecline: 'Decline',
      },
      globalDialogHandleClose: (param) => {},
    }));
  };

  return (
    <ThemeProvider theme={theme}>
      <Dialog
        open={dialogOpen}
        //onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{Main.APP_PARAMS.globalState.globalDialogData.title}</DialogTitle>
        <DialogContent>{Main.APP_PARAMS.globalState.globalDialogData.message}</DialogContent>
        <DialogActions>
          <Button onClick={handleSelfClose}>{Main.APP_PARAMS.globalState.globalDialogData.buttonDecline}</Button>
          <Button onClick={handleClose}>{Main.APP_PARAMS.globalState.globalDialogData.buttonOK}</Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
}

export default DialogComponent;
