// vvv Utility functions
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

export function convertDateTimeToUTC(date_time_str) {
  //console.log('UTCdata: ' + date_time_str);
  let jsDate = new Date(date_time_str.replace(/-/g, '/'));
  //console.log('beforeUTC: ' + JSON.stringify(jsDate));
  let dayjsDate = dayjs.utc(jsDate).format('YYYY-MM-DD HH:mm');
  //console.log('afterUTC: ' + JSON.stringify(dayjsDate));
  return dayjsDate;
}

export function roundToDecimals(value, decimals) {
  return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals);
}

export function truncateDecimals(number, digits) {
  let multiplier = Math.pow(10, digits),
    adjustedNum = number * multiplier,
    truncatedNum = Math[adjustedNum < 0 ? 'ceil' : 'floor'](adjustedNum);

  return truncatedNum / multiplier;
}

export function getPropsByValueInObject(object, value) {
  let propsArr = [];
  let key = '';
  for (key in object) {
    //console.log('key: ' + key + ' value: ' + object[key]);
    if (String(object[key]) === String(value) || String(object[key]) === '') {
      //console.log('key 2: ' + key + ' value: ' + object[key]);
      propsArr.push(key);
    }
  }
  return propsArr;
}

export function trimStringToLength(string, length) {
  return string.substring(0, length);
}

export function validateText(str) {
  if (!str) return false;
  return Boolean(str.length > 1);
}
export function validateTextArea(str) {
  if (!str) return false;
  return Boolean(str.length > 20);
}

//Validators
export function validateEmail(mail) {
  const emailRule =
    /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/;

  if (emailRule.test(mail)) {
    return true;
  }
  return false;
}

export function validatePhone(phone) {
  /*
  Valid formats:
  (123) 456-7890
  (123)456-7890
  123-456-7890
  123.456.7890
  1234567890
  +31636363634
  075-63546725
  */
  var phoneRule = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
  if (phoneRule.test(phone)) {
    return true;
  }
  return false;
}

export function validateLatitude(lat) {
  //console.log('validateLatitude: ' + lat);
  var latRule = /^(\+|-)?(?:90(?:(?:\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,6})?))$/;
  if (latRule.test(lat)) {
    return true;
  }
  return false;
}

export function validateLongitude(lon) {
  //console.log('validateLongitude: ' + lon);
  var lonRule = /^(\+|-)?(?:180(?:(?:\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,6})?))$/;
  if (lonRule.test(lon)) {
    return true;
  }
  return false;
}

export function validatePassword(password) {
  /* The string must contain: 
    - at least 1 lowercase alphabetical character
    - at least 1 numeric character
    - at least one special character
    - must be at least 8 characters
  */
  const passwordRule = /^(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*]).{8,}$/;

  if (passwordRule.test(password)) {
    return true;
  }
  return false;
}

export function validatePushNotificationsText(text) {
  const textRule = /^[ A-Za-z0-9.,!?]*$/;
  if (textRule.test(text)) {
    return true;
  }
  return false;
}

//Token
export const isValidToken = (timestamp) => {
  //const now = Math.floor(new Date('Tuesday, May 24, 2022 8:02:11 PM') / 1000);
  const now = Math.floor(Date.now() / 1000);
  //console.log('now: ' + now + ' token: ' + timestamp);
  //console.log('compare: ' + (timestamp - now));
  return timestamp - now > 0;
};

export const resetToken = (setGlobalState) => {
  //console.log('resetToken');
  localStorage.removeItem('token');
  localStorage.removeItem('loggedVenueData');
  if (window.location.pathname !== '/') {
    window.location.href = '/';

    setGlobalState((prevState) => ({
      ...prevState,
      toastOpen: true,
      toastMessage: 'Session timeout, please retry!',
      toastAutohideDuration: 15000,
      toastStyle: 'error',
      authToken: null,
    }));
  }
};

//
