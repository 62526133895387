import React, { useState, useEffect } from 'react';

import { ThemeProvider, Grid, createTheme, Paper, CardMedia, Typography, TextField, Button } from '@mui/material';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import useServerRequest from '../../hooks/serverRequest';
import { API_ENDPOINTS_LIST } from '../../commonUtils/constants';
//import { ROUTES_LIST } from '../../commonUtils/constants';

import { validatePushNotificationsText } from '../../commonUtils/commonUtils';

import HeaderComponent from '../Components/HeaderComponent/HeaderComponent';

import PNHistoryComponent from './Components/PNHistoryMainComponent';

//import noImage from '../../assets/no_image_placeholder.png';
import logoImage from '../../assets/ToxonLogo.png';
import archeryImage from '../../assets/archery-icon.png';
import headImage from '../../assets/add_event_head.jpg';
import commonStyles from '../../commonUtils/commonStyles';

import Main from '../../main/Main';

const theme = createTheme({
  palette: {
    secondary: {
      main: commonStyles.color.blue,
    },
    primary: {
      main: commonStyles.color.firmGrey,
    },
    action: {
      disabledBackground: commonStyles.color.blueDisabled,
      disabled: commonStyles.color.white,
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
});

export default function VenuePushNotificationsScreen() {
  const setGlobalLoading = (val) => {
    Main.APP_PARAMS.setGlobalState((prevState) => ({
      ...prevState,
      globalLoading: val,
    }));
  };

  const [submitButtonState, setSubmitButtonState] = useState(true);
  const [venuesListState, setVenuesListState] = useState([]);
  const [pnListState, setPnListState] = useState([]);
  const [state, setState] = useState({
    venueName: '',
    vanueData: '',
    venueAdmin: false,
    global: true,
    title: '',
    titleError: false,
    message: '',
    messageError: false,
  });

  const { getRequest: loadPNHistoryData } = useServerRequest(setGlobalLoading);
  const { getRequest: loadVenuesListData } = useServerRequest(setGlobalLoading);
  const { getRequest: uploadData } = useServerRequest(setGlobalLoading);
  const { getRequest: changePermissions } = useServerRequest(setGlobalLoading);

  useEffect(() => {
    //console.log('Load initial data');
    const venueData = JSON.parse(localStorage.getItem('loggedVenueData'));
    setState((prevState) => ({
      ...prevState,
      venueData: venueData,
      venueName: venueData.name,
      venueAdmin: venueData.admin,
    }));

    const venueId = '';
    loadPushNotificationsHistoryData(venueId);
    loadApprovedVenuesData();
  }, []);

  const loadApprovedVenuesData = () => {
    loadVenuesListData(API_ENDPOINTS_LIST.getVenuesInitialData, '', 'GET').then((res) => {
      //console.log('loadTableData result: ' + JSON.stringify(res));
      //console.log('loadTableData result: ' + JSON.stringify(res.clientsStatuses));

      if (res && !res.code) {
        //console.log('loadInitialDataHandler: ' + JSON.stringify(res));
        //setMetaDataState(res);
        setVenuesListState(res.venues);
        //setShootTypesState(res.shootTypes);
      } else {
        let errorText = '';

        if (res.status === 401) {
          errorText = "You don't have access to server";
        }

        if (res.status === 403) {
          errorText = 'Forbidden';
        }

        Main.APP_PARAMS.setGlobalState((prevState) => ({
          ...prevState,
          toastOpen: true,
          toastMessage: `Error! ${errorText}`,
          toastStyle: 'error',
        }));
      }
    });
  };

  const loadPushNotificationsHistoryData = (venueId) => {
    loadPNHistoryData(API_ENDPOINTS_LIST.getPushNotificationsHistory + '?venueId=' + venueId, '', 'GET', true).then(
      (res) => {
        //console.log('res:' + JSON.stringify(res));
        if (res && res.status === 200) {
          setPnListState(res.history);
        } else {
          Main.APP_PARAMS.setGlobalState((prevState) => ({
            ...prevState,
            toastOpen: true,
            toastMessage: `Error! Can't add data to server. Please try again or contact us!`,
            toastStyle: 'error',
          }));
        }
      }
    );
  };

  useEffect(() => {
    //call function when something change in state
    //check if all mandatory fields is not empty!
    let allFieldsFilled = false;

    //console.log('[dynamicComponentsState: ' + JSON.stringify(dynamicComponentsState));
    let errorsObj = {
      titleError: state.title === '' ? true : false,
      messageError: state.message === '' ? true : false,
    };

    //console.log('A: ' + JSON.stringify(Object.values(errorsObj)));

    if (
      Object.values(errorsObj).every((item) => {
        return item === false;
      })
    ) {
      allFieldsFilled = true;
    } else {
      allFieldsFilled = false;
    }
    //console.log('allFieldsFilled: ' + allFieldsFilled);

    setSubmitButtonState(!allFieldsFilled);
  }, [state]); //dependency added

  const updateText = (name, val) => {
    setState((prevState) => ({
      ...prevState,
      [name]: val,
    }));
  };

  const handleSwitchChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const handleSubmitButton = () => {
    if (!validatePushNotificationsText(state.title)) {
      setState((prevState) => ({
        ...prevState,
        titleError: true,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        titleError: false,
      }));
    }
    if (!validatePushNotificationsText(state.message)) {
      setState((prevState) => ({
        ...prevState,
        messageError: true,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        messageError: false,
      }));
    }
    if (validatePushNotificationsText(state.title) && validatePushNotificationsText(state.message)) {
      let dataForServer = {
        title: state.title,
        message: state.message,
      };
      if (state.global) dataForServer.modificator = 'global';
      //console.log('data for server: ');
      //console.log(JSON.stringify(dataForServer));

      uploadData(API_ENDPOINTS_LIST.venueSendPushNotification, JSON.stringify(dataForServer), 'POST', true).then(
        (res) => {
          //console.log('res:' + JSON.stringify(res));
          if (res && res.status === 200) {
            Main.APP_PARAMS.setGlobalState((prevState) => ({
              ...prevState,
              toastOpen: true,
              toastMessage: `Your Push Notification successfully sent to Toxon App users! Thank you!`,
              toastStyle: 'success',
            }));
          } else {
            Main.APP_PARAMS.setGlobalState((prevState) => ({
              ...prevState,
              toastOpen: true,
              toastMessage: `Error! Can't add data to server. Please try again or contact us!`,
              toastStyle: 'error',
            }));
          }
        }
      );
    }
  };

  const handleChangeVenuePermission = (venueId, enable) => {
    //console.log('handleChangeVenuePermission. venueId: ' + venueId + ' enable: ' + enable);
    const dataForServer = {
      venueId: venueId,
      enable: enable,
    };
    changePermissions(
      API_ENDPOINTS_LIST.changePushNotificationsPermission,
      JSON.stringify(dataForServer),
      'POST',
      true
    ).then((res) => {
      //console.log('res:' + JSON.stringify(res));
      loadApprovedVenuesData();
      if (res && res.status === 200) {
        Main.APP_PARAMS.setGlobalState((prevState) => ({
          ...prevState,
          toastOpen: true,
          toastMessage: `Push Notifications permissions for venue updated!`,
          toastStyle: 'success',
        }));
      } else {
        Main.APP_PARAMS.setGlobalState((prevState) => ({
          ...prevState,
          toastOpen: true,
          toastMessage: `Error! Can't update Push Notifications permissions for venue!`,
          toastStyle: 'error',
        }));
      }
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <HeaderComponent screenNumber={8} />
      <Paper style={getStyles().mainPaper} elevation={0}>
        <Grid container direction="row" style={getStyles().topTextGrid}>
          <Grid item xl={3} lg={3} md={3} sm={12} xs={12} style={getStyles().topTextGridColumn1}>
            <Grid item>
              <Typography noWrap style={commonStyles.text.robotoBold30}>
                Toxon App
              </Typography>
            </Grid>
            <Grid container direction="row" alignItems="center">
              <Grid item>
                <CardMedia component="img" image={archeryImage} style={getStyles().archeryImage} />
              </Grid>
              <Grid item>
                <Typography>Archery. Connected.</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xl={9} lg={9} md={9} sm={12} xs={12} style={getStyles().topTextGridColumn2}>
            <CardMedia component="img" image={headImage} style={getStyles().headImage} />
            <Grid container>
              <Grid item>
                <CardMedia component="img" image={logoImage} style={getStyles().logoImage} />
              </Grid>
            </Grid>
            <Grid item xs={12} align="center">
              <Typography
                style={{
                  ...commonStyles.text.robotoRegular24,
                  ...{ marginTop: '70px' },
                }}
              >
                Venue Push Notifications
              </Typography>
              {state.venueAdmin === true ? (
                <Typography style={{ ...commonStyles.text.robotoRegular18Light, color: 'red' }}>
                  You are logged in as Admin!
                </Typography>
              ) : null}
              <Typography style={{ ...commonStyles.text.robotoRegular18Light }}>
                Send Push Notifications to subscribed users using this form.
              </Typography>
              <Typography
                style={{
                  ...commonStyles.text.robotoRegular24,
                  ...{ marginTop: '30px' },
                }}
              >
                Send Push Notification
              </Typography>
              {state.venueAdmin === true ? (
                <Grid item xl={6} lg={6} md={6} sm={6} xs={6} style={{ marginTop: '50px' }}>
                  <FormGroup>
                    <FormControlLabel
                      control={<Switch checked={state.global} onChange={handleSwitchChange} name="global" />}
                      label="Send Globally (all app users)"
                    />
                  </FormGroup>
                  <Typography style={{ ...commonStyles.text.robotoRegular16 }}>
                    <span style={getStyles().redText}>*</span> if enabled you will send Push Notifications to all users
                    of the app! If disabled you will send Push Notifications only to subscribers of this venue!
                  </Typography>
                </Grid>
              ) : null}
              <Grid item xl={6} lg={6} md={6} sm={6} xs={6} style={{ marginTop: '50px' }}>
                <TextField
                  fullWidth
                  label="Title"
                  variant="outlined"
                  value={state.title}
                  error={state.titleError}
                  onChange={(e) => {
                    updateText('title', e.target.value);
                  }}
                />
              </Grid>
              <Typography style={{ ...commonStyles.text.robotoRegular16 }}>
                <span style={getStyles().redText}>*</span> Allowed symbols: [A-Z] [0-9] . , ! ?
              </Typography>
              <Grid item xl={6} lg={6} md={6} sm={6} xs={6} style={{ marginTop: '20px' }}>
                <TextField
                  fullWidth
                  label="Message"
                  variant="outlined"
                  value={state.message}
                  error={state.messageError}
                  onChange={(e) => {
                    updateText('message', e.target.value);
                  }}
                />
              </Grid>
              <Typography style={{ ...commonStyles.text.robotoRegular16 }}>
                <span style={getStyles().redText}>*</span> Allowed symbols: [A-Z] [0-9] . , ! ?
              </Typography>
              <Grid item xl={6} lg={6} md={6} sm={6} xs={6} style={{ marginTop: '20px' }}>
                <Button
                  variant="contained"
                  disabled={submitButtonState}
                  color="secondary"
                  style={{ color: commonStyles.color.white }}
                  onClick={() => {
                    handleSubmitButton();
                  }}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
            <Grid item xs={12} align="center">
              <PNHistoryComponent
                isAdmin={state.venueAdmin}
                venuesListData={venuesListState}
                pnListData={pnListState}
                updatePNForSelectedVenue={(venueId) => loadPushNotificationsHistoryData(venueId)}
                changeVenuePNPermission={(venueId, enable) => handleChangeVenuePermission(venueId, enable)}
              />
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </ThemeProvider>
  );
}

const getStyles = () => {
  const styles = {
    mainPaper: { marginTop: '100px' },
    archeryImage: {
      maxWidth: '40px',
    },
    topTextGrid: {
      marginTop: '70px',
      minHeight: '100px',
      maxWidth: '100%',
      //background: '#FFAA00',
    },
    topTextGridColumn1: {
      //background: '#DDFF99',
      padding: '30px',
    },
    topTextGridColumn2: {
      //background: '#99FF99',
      padding: '30px',
      userSelect: 'none',
    },
    headImage: {
      maxHeight: '250px',
    },
    logoImage: {
      position: 'absolute',
      maxWidth: '100px',
      marginTop: '-50px',
      marginLeft: '30px',
    },
    venueImage: {
      //maxHeight: '250px',
      maxWidth: '250px',
      //paddingTop: '30px',
      paddingBottom: '30px',
    },
    textHeader: { marginTop: '30px', marginBottom: '30px' },
    redText: { color: '#FF0000' },
  };
  return styles;
};
