import React, { useState, useEffect } from 'react';

import { ThemeProvider, Grid, createTheme, Paper, CardMedia, Typography, TextField, Button } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

import useUploadRequest from '../../hooks/uploadRequest';
import useServerRequest from '../../hooks/serverRequest';
import { API_ENDPOINTS_LIST } from '../../commonUtils/constants';
//import { ROUTES_LIST } from '../../commonUtils/constants';

import HeaderComponent from '../Components/HeaderComponent/HeaderComponent';

//import noImage from '../../assets/no_image_placeholder.png';
import logoImage from '../../assets/ToxonLogo.png';
import archeryImage from '../../assets/archery-icon.png';
import headImage from '../../assets/add_venue_head.jpg';
import commonStyles from '../../commonUtils/commonStyles';
import { validateEmail, validatePhone, validateLatitude, validateLongitude } from '../../commonUtils/commonUtils';

import Main from '../../main/Main';

const theme = createTheme({
  palette: {
    secondary: {
      main: commonStyles.color.blue,
    },
    primary: {
      main: commonStyles.color.firmGrey,
    },
    action: {
      disabledBackground: commonStyles.color.blueDisabled,
      disabled: commonStyles.color.white,
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
});

//const fileRef = React.createRef();

export default function RegisterVenueScreen() {
  const setGlobalLoading = (val) => {
    Main.APP_PARAMS.setGlobalState((prevState) => ({
      ...prevState,
      globalLoading: val,
    }));
  };

  const [submitButtonState, setSubmitButtonState] = useState(false);
  const [state, setState] = useState({
    name: '',
    nameError: false,
    contact: '',
    contactError: false,
    phone: '',
    phoneError: false,
    website: '',
    image: 'https://toxon-bucket.s3.us-east-2.amazonaws.com/images/bf61061c-47cc-448b-9494-c7af1a62bbc4.png',
    imageError: false,
    type: '',
    typeError: false,
    address: '',
    email: '',
    emailError: false,
    description: '',
    latitude: '',
    latitudeError: false,
    longitude: '',
    longitudeError: false,
  });

  //const { uploadRequest: uploadImage } = useUploadRequest(setGlobalLoading);
  const { getRequest: uploadData } = useServerRequest(setGlobalLoading);

  //const { getRequest: getSignedImageUrl } = useServerRequest(setGlobalLoading);

  const updateText = (name, val) => {
    setState((prevState) => ({
      ...prevState,
      [name]: val,
    }));
  };

  const handleRadioButtonsChange = (val) => {
    setState((prevState) => ({
      ...prevState,
      type: val,
    }));
  };

  const handleSubmitButton = () => {
    //check for correct fields
    let errorsObj = {
      imageError: state.image === '' ? true : false,
      emailError: state.email !== '' ? !validateEmail(state.email) : false,
      phoneError: state.phone !== '' ? !validatePhone(state.phone) : false,
      latitudeError: !validateLatitude(state.latitude),
      longitudeError: !validateLongitude(state.longitude),
    };
    //console.log('validateLatLon(state.latitude): ' + validateLatitude(state.latitude));
    //console.log('validateLatLon(state.longitude): ' + validateLongitude(state.longitude));
    if (
      Object.values(errorsObj).every((item) => {
        return item === false;
      })
    ) {
      //everything Ok - send data
      setState((prevState) => ({
        ...prevState,
        imageError: false,
        emailError: false,
        phoneError: false,
        latitudeError: false,
        longitudeError: false,
      }));

      const dataForServer = {
        venue: {
          name: state.name,
          contact: state.contact,
          phone: state.phone,
          email: state.email,
          website: state.website,
          images: [state.image],
          type: state.type,
          address: state.address,
          description: state.description,
          latitude: Number(state.latitude),
          longitude: Number(state.longitude),
        },
      };
      //console.log('data for server: ');
      //console.log(JSON.stringify(dataForServer));
      uploadData(API_ENDPOINTS_LIST.addHost, JSON.stringify(dataForServer)).then((res) => {
        if (res && res.status === 200) {
          Main.APP_PARAMS.setGlobalState((prevState) => ({
            ...prevState,
            toastOpen: true,
            //toastMessage: `Thank you for registration! Please check your email for access code! Next step: payment`,
            toastMessage: `Thank you for registration! Please check your email for access code!`,
            toastStyle: 'success',
          }));
          //console.log(JSON.stringify(res.venueId));
          //const type = state.type === 'pro-Shop' ? 1 : 0;
          //history.push('/payment?venueId=' + res.venueId + '&pt=' + type);
        } else {
          Main.APP_PARAMS.setGlobalState((prevState) => ({
            ...prevState,
            toastOpen: true,
            toastMessage: `Error! Can't add data to server. Please try again or contact us!`,
            toastStyle: 'error',
          }));
        }
      });
    } else {
      Main.APP_PARAMS.setGlobalState((prevState) => ({
        ...prevState,
        toastOpen: true,
        toastMessage: `Some fields is incorrect!`,
        toastStyle: 'error',
      }));
      setState((prevState) => ({
        ...prevState,
        imageError: errorsObj.imageError,
        emailError: errorsObj.emailError,
        phoneError: errorsObj.phoneError,
        latitudeError: errorsObj.latitudeError,
        longitudeError: errorsObj.longitudeError,
      }));
    }
  };

  useEffect(() => {
    //call function when something change in state
    //check if all mandatory fields is not empty!
    let allFieldsFilled = false;
    let errorsObj = {
      nameError: state.name === '' ? true : false,
      contactError: state.contact === '' ? true : false,
      phoneError: state.phone === '' ? true : false,
      //imageError: state.image === '' ? true : false,
      typeError: state.type === '' ? true : false,
      emailError: state.email === '' ? true : false,
      latitudeError: state.latitude === '' ? true : false,
      longitudeError: state.longitude === '' ? true : false,
    };

    //console.log('A: ' + JSON.stringify(Object.values(errorsObj)));

    if (
      Object.values(errorsObj).every((item) => {
        return item === false;
      })
    ) {
      allFieldsFilled = true;
    } else {
      allFieldsFilled = false;
    }
    //console.log('allFieldsFilled: ' + allFieldsFilled);

    setSubmitButtonState(!allFieldsFilled);
  }, [state]); //dependency added

  /*
  const handleFileUpload = () => {
    fileRef.current.click();
  };
  
  const handleImageUpload = async (event) => {
    //console.log("handleImageUpload: " + fileRef.current.files.length);
    if (fileRef.current.files.length) {
      // start preloader
      const input = document.querySelector('input[type="file"]');
      const [file] = input.files;
      const fileData = new FormData();
      fileData.append('file', file);
      //uploadPDF('preview', fileData, fileRef.current.files[0].name);
      //console.log("fileData: " + JSON.stringify(fileData));

      const uploadFileUrl = await getSignedImageUrl(
        API_ENDPOINTS_LIST.getSignedFileUrl + '?format=' + file.type.split('/')[1],
        '',
        'GET'
      );

      const imageUploaded = await uploadImage(uploadFileUrl.fileUrl, file, false);

      if (imageUploaded.status === 200) {
        setState((prevState) => ({
          ...prevState,
          image: uploadFileUrl.fileUrl.split('?')[0],
        }));
      } else {
        Main.APP_PARAMS.setGlobalState((prevState) => ({
          ...prevState,
          toastOpen: true,
          toastMessage: `Upload was not successful!`,
          toastStyle: 'error',
        }));
      }

      // uploadImage(
      //   API_ENDPOINTS_LIST.uploadImageGetPreview,
      //   fileData,
      //   false
      // ).then((res) => {
      //   console.log("Upload res: " + res.data.imageUrls);
      //   if (res && res.status === 200) {
      //     setState((prevState) => ({
      //       ...prevState,
      //       image: res.data.imageUrls,
      //     }));
      //   } else {
      //     Main.APP_PARAMS.setGlobalState((prevState) => ({
      //       ...prevState,
      //       toastOpen: true,
      //       toastMessage: `Upload was not successful!`,
      //       toastStyle: "error",
      //     }));
      //   }
      // });
    }
  };
  */

  return (
    <ThemeProvider theme={theme}>
      <HeaderComponent screenNumber={4} />
      <Paper style={getStyles().mainPaper} elevation={0}>
        <Grid container direction="row" style={getStyles().topTextGrid}>
          <Grid item xl={3} lg={3} md={3} sm={12} xs={12} style={getStyles().topTextGridColumn1}>
            <Grid item>
              <Typography noWrap style={commonStyles.text.robotoBold30}>
                Toxon App
              </Typography>
            </Grid>
            <Grid container direction="row" alignItems="center">
              <Grid item>
                <CardMedia component="img" image={archeryImage} style={getStyles().archeryImage} />
              </Grid>
              <Grid item>
                <Typography>Archery. Connected.</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xl={9} lg={9} md={9} sm={12} xs={12} style={getStyles().topTextGridColumn2}>
            <CardMedia component="img" image={headImage} style={getStyles().headImage} />
            <Grid container>
              <Grid item>
                <CardMedia component="img" image={logoImage} style={getStyles().logoImage} />
              </Grid>
            </Grid>
            <Grid item xs={12} align="center">
              <Typography
                style={{
                  ...commonStyles.text.robotoRegular24,
                  ...{ marginTop: '70px' },
                }}
              >
                Register
              </Typography>
              <Typography style={{ ...commonStyles.text.robotoRegular18Light }}>
                Register your club or pro-shop with Toxon!
              </Typography>
            </Grid>
            <Grid item xs={12} align="center" style={{ ...getStyles().textHeader }}>
              <Grid container justifyContent="center">
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={8}
                  lg={8}
                  xl={8}
                  style={{
                    width: '100%',
                    justifyContent: 'flex-start',
                    textAlign: 'start',
                  }}
                >
                  <Typography style={{ ...commonStyles.text.robotoRegular20bold }}>
                    Name <span style={getStyles().redText}>*</span>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} align="center">
              <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                <TextField
                  fullWidth
                  label="Name of Club or Pro-Shop"
                  variant="outlined"
                  value={state.name}
                  error={state.nameError}
                  onChange={(e) => {
                    updateText('name', e.target.value);
                  }}
                />
              </Grid>
            </Grid>

            <Grid item xs={12} align="center" style={{ ...getStyles().textHeader }}>
              <Grid container justifyContent="center">
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={8}
                  lg={8}
                  xl={8}
                  style={{
                    width: '100%',
                    justifyContent: 'flex-start',
                    textAlign: 'start',
                  }}
                >
                  <Typography style={{ ...commonStyles.text.robotoRegular20bold }}>
                    Contact person's name <span style={getStyles().redText}>*</span>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} align="center">
              <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                <TextField
                  fullWidth
                  label="John Smith"
                  variant="outlined"
                  value={state.contact}
                  error={state.contactError}
                  onChange={(e) => {
                    updateText('contact', e.target.value);
                  }}
                />
              </Grid>
            </Grid>

            <Grid item xs={12} align="center" style={{ ...getStyles().textHeader }}>
              <Grid container justifyContent="center">
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={8}
                  lg={8}
                  xl={8}
                  style={{
                    width: '100%',
                    justifyContent: 'flex-start',
                    textAlign: 'start',
                  }}
                >
                  <Typography style={{ ...commonStyles.text.robotoRegular20bold }}>
                    Phone <span style={getStyles().redText}>*</span>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} align="center">
              <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                <TextField
                  fullWidth
                  label="+12223334455"
                  variant="outlined"
                  value={state.phone}
                  error={state.phoneError}
                  onChange={(e) => {
                    updateText('phone', e.target.value);
                  }}
                />
              </Grid>
            </Grid>

            <Grid item xs={12} align="center" style={{ ...getStyles().textHeader }}>
              <Grid container justifyContent="center">
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={8}
                  lg={8}
                  xl={8}
                  style={{
                    width: '100%',
                    justifyContent: 'flex-start',
                    textAlign: 'start',
                  }}
                >
                  <Typography style={{ ...commonStyles.text.robotoRegular20bold }}>Website</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} align="center">
              <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                <TextField
                  fullWidth
                  label="www.somesite.com"
                  variant="outlined"
                  value={state.website}
                  onChange={(e) => {
                    updateText('website', e.target.value);
                  }}
                />
              </Grid>
            </Grid>
            {/*
            <Grid
              item
              xs={12}
              align="center"
              style={{
                ...getStyles().textHeader,
              }}
            >
              <Grid container justifyContent="center">
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={8}
                  lg={8}
                  xl={8}
                  style={{
                    width: '100%',
                    justifyContent: 'flex-start',
                    textAlign: 'start',
                  }}
                >
                  <Typography style={{ ...commonStyles.text.robotoRegular20bold }}>
                    Image <span style={getStyles().redText}>*</span>
                  </Typography>
                  <Typography style={{ ...commonStyles.text.robotoRegular18Light }}>
                    This is the picture we'll show in the app along with your name.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} align="center">
              <Grid
                item
                xs={12}
                sm={12}
                md={8}
                lg={8}
                xl={8}
                style={state.imageError === true ? { border: '1px solid', borderColor: 'red' } : null}
              >
                <img style={getStyles().venueImage} src={state.image === '' ? noImage : state.image} alt="" />
              </Grid>
              <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                <Button
                  variant="contained"
                  color="default"
                  style={{ color: commonStyles.color.black }}
                  onClick={() => {
                    handleFileUpload();
                  }}
                >
                  Select & Upload
                </Button>
                <input type="file" hidden ref={fileRef} onChange={(event) => handleImageUpload(event)} accept="file" />
              </Grid>
            </Grid>
            */}
            <Grid item xs={12} align="center" style={{ ...getStyles().textHeader }}>
              <Grid container justifyContent="center">
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={8}
                  lg={8}
                  xl={8}
                  style={{
                    width: '100%',
                    justifyContent: 'flex-start',
                    textAlign: 'start',
                  }}
                >
                  <Typography style={{ ...commonStyles.text.robotoRegular20bold }}>
                    Type <span style={getStyles().redText}>*</span>
                  </Typography>
                  <Typography style={{ ...commonStyles.text.robotoRegular18Light }}>
                    <span style={{ fontWeight: 'bold' }}>
                      <span style={{ fontSize: '1.4rem' }}>&bull;</span>
                    </span>
                    {'   '} Pro-shops are full archery store-fronts that may or may not have an archery range.
                    <br />
                  </Typography>
                  <Typography style={{ ...commonStyles.text.robotoRegular18Light }}>
                    <span style={{ fontWeight: 'bold' }}>
                      <span style={{ fontSize: '1.4rem' }}>&bull;</span>
                    </span>
                    {'   '} Clubs are shooting locations without a storefront.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} align="center">
              <Grid
                item
                xs={12}
                sm={12}
                md={8}
                lg={8}
                xl={8}
                style={state.typeError === true ? { border: '1px solid', borderColor: 'red' } : null}
              >
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="type"
                    name="radio-buttons-group"
                    onChange={(e) => {
                      handleRadioButtonsChange(e.target.value);
                    }}
                  >
                    <FormControlLabel value="pro-Shop" control={<Radio color="secondary" />} label="Pro-Shop" />
                    <FormControlLabel value="club" control={<Radio color="secondary" />} label="Club" />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>

            <Grid item xs={12} align="center" style={{ ...getStyles().textHeader }}>
              <Grid container justifyContent="center">
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={8}
                  lg={8}
                  xl={8}
                  style={{
                    width: '100%',
                    justifyContent: 'flex-start',
                    textAlign: 'start',
                  }}
                >
                  <Typography style={{ ...commonStyles.text.robotoRegular20bold }}>Full Street Address</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} align="center">
              <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                <TextField
                  fullWidth
                  label="44 Garden St, Bensalem, PA 19020"
                  variant="outlined"
                  value={state.address}
                  onChange={(e) => {
                    updateText('address', e.target.value);
                  }}
                />
              </Grid>
            </Grid>

            <Grid item xs={12} align="center" style={{ ...getStyles().textHeader }}>
              <Grid container justifyContent="center">
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={8}
                  lg={8}
                  xl={8}
                  style={{
                    width: '100%',
                    justifyContent: 'flex-start',
                    textAlign: 'start',
                  }}
                >
                  <Typography style={{ ...commonStyles.text.robotoRegular20bold }}>
                    Email <span style={getStyles().redText}>*</span>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} align="center">
              <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                <TextField
                  fullWidth
                  label="some@email.com"
                  variant="outlined"
                  value={state.email}
                  error={state.emailError}
                  onChange={(e) => {
                    updateText('email', e.target.value);
                  }}
                />
              </Grid>
            </Grid>

            <Grid item xs={12} align="center" style={{ ...getStyles().textHeader }}>
              <Grid container justifyContent="center">
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={8}
                  lg={8}
                  xl={8}
                  style={{
                    width: '100%',
                    justifyContent: 'flex-start',
                    textAlign: 'start',
                  }}
                >
                  <Typography style={{ ...commonStyles.text.robotoRegular20bold }}>Description</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} align="center">
              <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                <TextField
                  fullWidth
                  multiline
                  minRows={3}
                  label="Write something about your club or pro-shop"
                  variant="outlined"
                  value={state.description}
                  onChange={(e) => {
                    updateText('description', e.target.value);
                  }}
                />
              </Grid>
            </Grid>

            <Grid item xs={12} align="center" style={{ ...getStyles().textHeader }}>
              <Grid container justifyContent="center">
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={8}
                  lg={8}
                  xl={8}
                  style={{
                    width: '100%',
                    justifyContent: 'flex-start',
                    textAlign: 'start',
                  }}
                >
                  <Typography style={{ ...commonStyles.text.robotoRegular20bold }}>
                    Latitude/Longitude <span style={getStyles().redText}>*</span>
                  </Typography>
                  <Typography style={{ ...commonStyles.text.robotoRegular18Light }}>
                    We use latitude and longitude to show your location on the map.
                  </Typography>
                  <Typography style={{ ...commonStyles.text.robotoRegular18Light }}>
                    Not sure what's your lat, lon coordinates? Use this free service:{' '}
                    <a href="https://www.latlong.net/convert-address-to-lat-long.html" target="_blank" rel="noreferrer">
                      www.LatLong.net
                    </a>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} align="center" style={{ ...{ marginBottom: '50px' } }}>
              <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                <TextField
                  fullWidth
                  label="Latitude"
                  variant="outlined"
                  value={state.latitude}
                  error={state.latitudeError}
                  onChange={(e) => {
                    updateText('latitude', e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={8} lg={8} xl={8} style={{ marginTop: '30px' }}>
                <TextField
                  fullWidth
                  label="Longitude"
                  variant="outlined"
                  value={state.longitude}
                  error={state.longitudeError}
                  onChange={(e) => {
                    updateText('longitude', e.target.value);
                  }}
                />
              </Grid>

              <Grid item xs={12} align="center" style={{ ...getStyles().textHeader }}>
                <Grid container justifyContent="center">
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={8}
                    lg={8}
                    xl={8}
                    style={{
                      width: '100%',
                      justifyContent: 'flex-start',
                      textAlign: 'start',
                    }}
                  >
                    <Button
                      variant="contained"
                      disabled={submitButtonState}
                      color="secondary"
                      style={{ color: commonStyles.color.white }}
                      onClick={() => {
                        handleSubmitButton();
                      }}
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} align="center" style={{ ...getStyles().textHeader }}>
                <Grid container justifyContent="center">
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={8}
                    lg={8}
                    xl={8}
                    style={{
                      width: '100%',
                      justifyContent: 'flex-start',
                      textAlign: 'start',
                    }}
                  >
                    <Typography style={{ ...commonStyles.text.robotoRegular16 }}>
                      - all fields marked with <span style={getStyles().redText}>*</span> are mandatory
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </ThemeProvider>
  );
}

const getStyles = () => {
  const styles = {
    mainPaper: { marginTop: '100px' },
    archeryImage: {
      maxWidth: '40px',
    },
    topTextGrid: {
      marginTop: '70px',
      minHeight: '100px',
      maxWidth: '100%',
      //background: '#FFAA00',
    },
    topTextGridColumn1: {
      //background: '#DDFF99',
      padding: '30px',
    },
    topTextGridColumn2: {
      //background: '#99FF99',
      padding: '30px',
      userSelect: 'none',
    },
    headImage: {
      maxHeight: '250px',
    },
    logoImage: {
      position: 'absolute',
      maxWidth: '100px',
      marginTop: '-50px',
      marginLeft: '30px',
    },
    venueImage: {
      //maxHeight: '250px',
      maxWidth: '250px',
      //paddingTop: '30px',
      paddingBottom: '30px',
    },
    textHeader: { marginTop: '30px', marginBottom: '30px' },
    redText: { color: '#FF0000' },
  };
  return styles;
};
