import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
// import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';

//const moment = require('moment');

const columns = [
  { id: 'venueName', label: 'Name', maxWidth: '100px', align: 'left' },
  {
    id: 'topic',
    label: 'Topic',
    maxWidth: '100px',
    align: 'left',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'title',
    label: 'Title',
    maxWidth: '100px',
    align: 'left',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'message',
    label: 'Message',
    maxWidth: '100px',
    align: 'left',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'createdAt',
    label: 'Date',
    maxWidth: '100px',
    align: 'left',
    format: (value) => dayjs(value).format('YYYY-MM-DD HH:mm'),
  },
];

// const useStyles = makeStyles({
//   root: {
//     width: '100%',
//   },
//   container: {
//     minHeight: '600px',
//   },
// });

const StyledPaper = styled(Paper)({
  width: '100%'
});

const StyledTableContainer = styled(TableContainer)({
  minHeight: '600px'
});


export default function PNHIstoryTableComponent({ dataForTable }) {
//  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [dataDows, setDataRows] = useState([]);

  useEffect(() => {
    //console.log('Initial data: ' + JSON.stringify(dataForTable));
    //const venueData = JSON.parse(localStorage.getItem('loggedVenueData'));
    if (dataForTable.length !== 0) {
      setDataRows(dataForTable);
    }
  }, [dataForTable]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <StyledPaper>
      <StyledTableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id} align={column.align} style={{ maxWidth: column.maxWidth }}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {dataDows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                  {columns.map((column) => {
                    const value = row[column.id];
                    return (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ maxWidth: column.maxWidth, whiteSpace: 'normal', wordWrap: 'break-word' }}
                      >
                        {column.format ? column.format(value) : value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </StyledTableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={dataDows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </StyledPaper>
  );
}
