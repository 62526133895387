import React, { useState } from 'react';

import { ThemeProvider, Grid, createTheme, Paper, CardMedia, Typography, TextField, Button } from '@mui/material';

import useServerRequest from '../../hooks/serverRequest';
import { API_ENDPOINTS_LIST } from '../../commonUtils/constants';
//import { ROUTES_LIST } from '../../commonUtils/constants';

import HeaderComponent from '../Components/HeaderComponent/HeaderComponent';

import archeryImage from '../../assets/archery-icon.png';
import commonStyles from '../../commonUtils/commonStyles';
import { validateEmail } from '../../commonUtils/commonUtils';

import Main from '../../main/Main';

const theme = createTheme({
  palette: {
    secondary: {
      main: commonStyles.color.blue,
    },
    primary: {
      main: commonStyles.color.firmGrey,
    },
    action: {
      disabledBackground: commonStyles.color.blueDisabled,
      disabled: commonStyles.color.white,
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
});

export default function ContactUsScreen() {
  const setGlobalLoading = (val) => {
    Main.APP_PARAMS.setGlobalState((prevState) => ({
      ...prevState,
      globalLoading: val,
    }));
  };

  const [state, setState] = useState({
    name: '',
    email: '',
    emailError: false,
    message: '',
  });

  const { getRequest: uploadData } = useServerRequest(setGlobalLoading);

  const updateText = (name, val) => {
    setState((prevState) => ({
      ...prevState,
      [name]: val,
    }));
  };

  const handleSubmitButton = () => {
    //console.log("email: " + state.email);
    //console.log("validateEmail: " + validateEmail(state.email));
    if (validateEmail(state.email)) {
      setState((prevState) => ({
        ...prevState,
        emailError: false,
      }));
      /*
      {
        "email": "customer@mail.com",
        "name": "Bruce Lee",
        "message": "Message about toxon application"
      }
      */
      const dataForServer = {
        name: state.name,
        email: state.email,
        message: state.message,
      };
      uploadData(API_ENDPOINTS_LIST.sendContactUsMessage, JSON.stringify(dataForServer)).then((res) => {
        if (res && res.status === 200) {
          Main.APP_PARAMS.setGlobalState((prevState) => ({
            ...prevState,
            toastOpen: true,
            toastMessage: `Your message has been successfully sent. We will contact you very soon!`,
            toastStyle: 'success',
          }));
        } else {
          Main.APP_PARAMS.setGlobalState((prevState) => ({
            ...prevState,
            toastOpen: true,
            toastMessage: `Error! Can't add data to server. Please try again or contact us!`,
            toastStyle: 'error',
          }));
        }
      });
      /*
      Main.APP_PARAMS.setGlobalState((prevState) => ({
        ...prevState,
        toastOpen: true,
        toastMessage: `Your message has been successfully sent. We will contact you very soon!`,
        toastStyle: 'success',
      }));
      */
    } else {
      setState((prevState) => ({
        ...prevState,
        emailError: true,
      }));
      Main.APP_PARAMS.setGlobalState((prevState) => ({
        ...prevState,
        toastOpen: true,
        toastMessage: `Wrong email!`,
        toastStyle: 'error',
      }));
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <HeaderComponent screenNumber={3} />
      <Paper style={getStyles().mainPaper} elevation={0}>
        <Grid container direction="row" style={getStyles().topTextGrid}>
          <Grid item xl={3} lg={3} md={3} sm={12} xs={12} style={getStyles().topTextGridColumn1}>
            <Grid item>
              <Typography noWrap style={commonStyles.text.robotoBold30}>
                Toxon App
              </Typography>
            </Grid>
            <Grid container direction="row" alignItems="center">
              <Grid item>
                <CardMedia component="img" image={archeryImage} style={getStyles().archeryImage} />
              </Grid>
              <Grid item>
                <Typography>Archery. Connected.</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xl={9} lg={9} md={9} sm={12} xs={12} style={getStyles().topTextGridColumn2}>
            <Grid item xs={12} align="center">
              <Typography style={commonStyles.text.robotoRegular24}>Contact Us</Typography>
            </Grid>
            <Grid item xs={12} align="center" style={{ ...getStyles().textHeader }}>
              <Grid container justifyContent="center">
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={8}
                  lg={8}
                  xl={8}
                  style={{
                    width: '100%',
                    justifyContent: 'flex-start',
                    textAlign: 'start',
                  }}
                >
                  <Typography style={{ ...commonStyles.text.robotoRegular20bold }}>
                    Your name <span style={getStyles().redText}>*</span>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} align="center">
              <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                <TextField
                  fullWidth
                  label="John Black"
                  variant="outlined"
                  value={state.name}
                  onChange={(e) => {
                    updateText('name', e.target.value);
                  }}
                />
              </Grid>
            </Grid>

            <Grid item xs={12} align="center" style={{ ...getStyles().textHeader }}>
              <Grid container justifyContent="center">
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={8}
                  lg={8}
                  xl={8}
                  style={{
                    width: '100%',
                    justifyContent: 'flex-start',
                    textAlign: 'start',
                  }}
                >
                  <Typography style={{ ...commonStyles.text.robotoRegular20bold }}>
                    Email <span style={getStyles().redText}>*</span>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} align="center">
              <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                <TextField
                  fullWidth
                  label="some@email.com"
                  variant="outlined"
                  value={state.email}
                  error={state.emailError}
                  onChange={(e) => {
                    updateText('email', e.target.value);
                  }}
                />
              </Grid>
            </Grid>

            <Grid item xs={12} align="center" style={{ ...getStyles().textHeader }}>
              <Grid container justifyContent="center">
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={8}
                  lg={8}
                  xl={8}
                  style={{
                    width: '100%',
                    justifyContent: 'flex-start',
                    textAlign: 'start',
                  }}
                >
                  <Typography style={{ ...commonStyles.text.robotoRegular20bold }}>
                    Message <span style={getStyles().redText}>*</span>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} align="center">
              <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                <TextField
                  fullWidth
                  multiline
                  minRows={3}
                  label="How can we help you?"
                  variant="outlined"
                  value={state.message}
                  onChange={(e) => {
                    updateText('message', e.target.value);
                  }}
                />
              </Grid>
            </Grid>

            <Grid item xs={12} align="center" style={{ ...getStyles().textHeader }}>
              <Grid container justifyContent="center">
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={8}
                  lg={8}
                  xl={8}
                  style={{
                    width: '100%',
                    justifyContent: 'flex-start',
                    textAlign: 'start',
                  }}
                >
                  <Button
                    variant="contained"
                    disabled={!(state.name !== '' && state.email !== '' && state.message !== '')}
                    color="secondary"
                    style={{ color: commonStyles.color.white }}
                    onClick={() => handleSubmitButton()}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} align="center" style={{ ...getStyles().textHeader }}>
              <Grid container justifyContent="center">
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={8}
                  lg={8}
                  xl={8}
                  style={{
                    width: '100%',
                    justifyContent: 'flex-start',
                    textAlign: 'start',
                  }}
                >
                  <Typography style={{ ...commonStyles.text.robotoRegular16 }}>
                    - all fields marked with <span style={getStyles().redText}>*</span> are mandatory
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </ThemeProvider>
  );
}

const getStyles = () => {
  const styles = {
    mainPaper: { marginTop: '100px' },
    archeryImage: {
      maxWidth: '40px',
    },
    topTextGrid: {
      marginTop: '70px',
      minHeight: '100px',
      maxWidth: '100%',
      //background: '#FFAA00',
    },
    topTextGridColumn1: {
      //background: '#DDFF99',
      padding: '30px',
    },
    topTextGridColumn2: {
      //background: '#99FF99',
      padding: '30px',
      userSelect: 'none',
    },
    textHeader: { marginTop: '30px', marginBottom: '30px' },
    textBlock: {
      borderBottom: '1px solid',
      borderBottomColor: 'rgba(0, 0, 0, 0.2)',
      paddingBottom: '15px',
    },
    redText: { color: '#FF0000' },
  };
  return styles;
};
