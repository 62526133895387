import React from 'react';
import { Backdrop, CircularProgress } from '@mui/material';

function LoaderComponent(props) {
  const { loading = false } = props;

  return (
    <Backdrop style={{ zIndex: 11500 }} open={loading}>
      <CircularProgress style={{ color: '#ffffff' }} />
    </Backdrop>
  );
}

export default LoaderComponent;
