import React, { useState, useEffect } from 'react';

import { ThemeProvider, Grid, createTheme, Paper, CardMedia, Typography } from '@mui/material';
import useServerRequest from '../../hooks/serverRequest';
import { API_ENDPOINTS_LIST } from '../../commonUtils/constants';
//import { ROUTES_LIST } from '../../commonUtils/constants';

import HeaderComponent from '../Components/HeaderComponent/HeaderComponent';

import EventsComponent from './Components/EventsMainComponent';

//import noImage from '../../assets/no_image_placeholder.png';
import logoImage from '../../assets/ToxonLogo.png';
import archeryImage from '../../assets/archery-icon.png';
import headImage from '../../assets/add_event_head.jpg';
import commonStyles from '../../commonUtils/commonStyles';

import Main from '../../main/Main';

const theme = createTheme({
  palette: {
    secondary: {
      main: commonStyles.color.blue,
    },
    primary: {
      main: commonStyles.color.firmGrey,
    },
    action: {
      disabledBackground: commonStyles.color.blueDisabled,
      disabled: commonStyles.color.white,
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
});

export default function VenueEventsScreen() {
  const setGlobalLoading = (val) => {
    Main.APP_PARAMS.setGlobalState((prevState) => ({
      ...prevState,
      globalLoading: val,
    }));
  };

  const [eventsListState, setEventsListState] = useState([]);
  const [state, setState] = useState({
    venueName: '',
    vanueData: '',
    venueAdmin: false,
  });

  const { getRequest: loadEventsData } = useServerRequest(setGlobalLoading);
  const { getRequest: deleteEventData } = useServerRequest(setGlobalLoading);
  //const { getRequest: uploadData } = useServerRequest(setGlobalLoading);

  useEffect(() => {
    //console.log('Load initial data');
    const venueData = JSON.parse(localStorage.getItem('loggedVenueData'));
    setState((prevState) => ({
      ...prevState,
      venueData: venueData,
      venueName: venueData.name,
      venueAdmin: venueData.admin,
    }));

    loadEventsListData();
  }, []);

  const loadEventsListData = () => {
    loadEventsData(API_ENDPOINTS_LIST.getEventsList, '', 'GET', true).then((res) => {
      //console.log('res:' + JSON.stringify(res));
      if (res && res.status === 200) {
        setEventsListState(res.listings);
      } else {
        Main.APP_PARAMS.setGlobalState((prevState) => ({
          ...prevState,
          toastOpen: true,
          toastMessage: `Error! Can't add data to server. Please try again or contact us!`,
          toastStyle: 'error',
        }));
      }
    });
  };

  const handleEventDelete = (eventId) => {
    deleteEventData(API_ENDPOINTS_LIST.deleteEvent + '/' + eventId, '', 'DELETE').then((res) => {
      if (res && res.status === 200) {
        loadEventsListData();
        Main.APP_PARAMS.setGlobalState((prevState) => ({
          ...prevState,
          toastOpen: true,
          toastMessage: `Your event/shoot is deleted!`,
          toastStyle: 'success',
        }));
      } else {
        Main.APP_PARAMS.setGlobalState((prevState) => ({
          ...prevState,
          toastOpen: true,
          toastMessage: `Error! Can't add data to server. Please try again or contact us!`,
          toastStyle: 'error',
        }));
      }
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <HeaderComponent screenNumber={8} />
      <Paper style={getStyles().mainPaper} elevation={0}>
        <Grid container direction="row" style={getStyles().topTextGrid}>
          <Grid item xl={3} lg={3} md={3} sm={12} xs={12} style={getStyles().topTextGridColumn1}>
            <Grid item>
              <Typography noWrap style={commonStyles.text.robotoBold30}>
                Toxon App
              </Typography>
            </Grid>
            <Grid container direction="row" alignItems="center">
              <Grid item>
                <CardMedia component="img" image={archeryImage} style={getStyles().archeryImage} />
              </Grid>
              <Grid item>
                <Typography>Archery. Connected.</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xl={9} lg={9} md={9} sm={12} xs={12} style={getStyles().topTextGridColumn2}>
            <CardMedia component="img" image={headImage} style={getStyles().headImage} />
            <Grid container>
              <Grid item>
                <CardMedia component="img" image={logoImage} style={getStyles().logoImage} />
              </Grid>
            </Grid>
            <Grid item xs={12} align="center">
              <Typography
                style={{
                  ...commonStyles.text.robotoRegular24,
                  ...{ marginTop: '70px' },
                }}
              >
                Venue Events
              </Typography>
              {state.venueAdmin === true ? (
                <Typography style={{ ...commonStyles.text.robotoRegular18Light, color: 'red' }}>
                  You are logged in as Admin!
                </Typography>
              ) : null}
              <Typography style={{ ...commonStyles.text.robotoRegular18Light }}>
                Review and edit your events for mobile app using this form.
              </Typography>
            </Grid>
            <Grid item xs={12} align="center">
              <EventsComponent eventsListData={eventsListState} eventDelete={(eventId) => handleEventDelete(eventId)} />
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </ThemeProvider>
  );
}

const getStyles = () => {
  const styles = {
    mainPaper: { marginTop: '100px' },
    archeryImage: {
      maxWidth: '40px',
    },
    topTextGrid: {
      marginTop: '70px',
      minHeight: '100px',
      maxWidth: '100%',
      //background: '#FFAA00',
    },
    topTextGridColumn1: {
      //background: '#DDFF99',
      padding: '30px',
    },
    topTextGridColumn2: {
      //background: '#99FF99',
      padding: '30px',
      userSelect: 'none',
    },
    headImage: {
      maxHeight: '250px',
    },
    logoImage: {
      position: 'absolute',
      maxWidth: '100px',
      marginTop: '-50px',
      marginLeft: '30px',
    },
    venueImage: {
      //maxHeight: '250px',
      maxWidth: '250px',
      //paddingTop: '30px',
      paddingBottom: '30px',
    },
    textHeader: { marginTop: '30px', marginBottom: '30px' },
    redText: { color: '#FF0000' },
  };
  return styles;
};
