import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { ThemeProvider, Grid, createTheme, Paper, CardMedia, Typography, Button, TextField } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import useServerRequest from '../../hooks/serverRequest';
import { API_ENDPOINTS_LIST } from '../../commonUtils/constants';
//import { ROUTES_LIST } from '../../commonUtils/constants';

import HeaderComponent from '../Components/HeaderComponent/HeaderComponent';

//import noImage from '../../assets/no_image_placeholder.png';
import logoImage from '../../assets/ToxonLogo.png';
import archeryImage from '../../assets/archery-icon.png';
import headImage from '../../assets/add_event_head.jpg';
import commonStyles from '../../commonUtils/commonStyles';
import { ROUTES_LIST } from '../../commonUtils/constants';

import { validateEmail, validatePhone, validateLatitude, validateLongitude } from '../../commonUtils/commonUtils';

import Main from '../../main/Main';

const theme = createTheme({
  palette: {
    secondary: {
      main: commonStyles.color.blue,
    },
    primary: {
      main: commonStyles.color.firmGrey,
    },
    action: {
      disabledBackground: commonStyles.color.blueDisabled,
      disabled: commonStyles.color.white,
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
});

const buttonsGroup = [
  {
    key: 'button-1',
    text: 'Add Event',
    link: ROUTES_LIST.addEvent,
  },
  {
    key: 'button-2',
    text: 'Create push notification',
    link: ROUTES_LIST.venuePushNotifications,
  },
  {
    key: 'button-3',
    text: 'Events',
    link: ROUTES_LIST.venueEvents,
  }
];

export default function VenuePersonalDataScreen() {
  const themeHook = useTheme();
  const isMdScreen = useMediaQuery(themeHook.breakpoints.up('md'));
  const navigate = useNavigate();
  const setGlobalLoading = (val) => {
    Main.APP_PARAMS.setGlobalState((prevState) => ({
      ...prevState,
      globalLoading: val,
    }));
  };

  const [state, setState] = useState({
    venueData: null,
    venueTypes: [],
    venueName: '',
    venueAdmin: false,
    venueDynamicComponents: [],
    editMode: false,
  });

  const [textState, setTextState] = useState({
    name: '',
    nameError: false,
    contact: '',
    contactError: false,
    phone: '',
    phoneError: false,
    email: '',
    emailError: false,
    type: '',
    website: '',
    address: '',
    description: '',
    latitude: 0,
    latitudeError: false,
    longitude: 0,
    longitudeError: false,
  });

  const [submitButtonState, setSubmitButtonState] = useState(false);

  //const { getRequest: loadInitialData } = useServerRequest(setGlobalLoading);
  const { getRequest: updateVenueInfo } = useServerRequest(setGlobalLoading);

  useEffect(() => {
    //console.log('Load initial data');
    prepareData();
  }, []);

  const prepareData = () => {
    const venueData = JSON.parse(localStorage.getItem('loggedVenueData'));
    const venueTypes = JSON.parse(localStorage.getItem('loggedVenueAvailableTypes'));
    //console.log('venueTypes: ' + JSON.stringify(venueTypes));
    let dynamicComponentsArray = [];
    Object.keys(venueData).forEach(function (key) {
      //console.log(key, state.venueData[key]);
      if (key !== 'admin') {
        dynamicComponentsArray.push(<VenueDataItem name={key} val={venueData[key]} keyVar={key} />);
        updateText(key, venueData[key]);
      }
    });
    setState((prevState) => ({
      ...prevState,
      venueData: venueData,
      venueName: venueData.name,
      venueAdmin: venueData.admin,
      venueDynamicComponents: dynamicComponentsArray,
      editMode: false,
    }));
  };

  useEffect(() => {
    //call function when something change in state
    //check if all mandatory fields is not empty!
    let allFieldsFilled = false;
    let errorsObj = {
      nameError: textState.name === '' ? true : false,
      contactError: textState.contact === '' ? true : false,
      phoneError: textState.phone === '' ? true : false,
      //imageError: state.image === '' ? true : false,
      emailError: textState.email === '' ? true : false,
      latitudeError: textState.latitude === '' ? true : false,
      longitudeError: textState.longitude === '' ? true : false,
    };

    //console.log('A: ' + JSON.stringify(Object.values(errorsObj)));

    if (
      Object.values(errorsObj).every((item) => {
        return item === false;
      })
    ) {
      allFieldsFilled = true;
    } else {
      allFieldsFilled = false;
    }
    //console.log('allFieldsFilled: ' + allFieldsFilled);

    setSubmitButtonState(!allFieldsFilled);
  }, [textState]); //dependency added

  const updateText = (name, val) => {
    //console.log('Update text: ' + name + ' val: ' + val);
    setTextState((prevState) => ({
      ...prevState,
      [name]: val,
    }));
  };

  const handleRadioButtonsChange = (val) => {
    setTextState((prevState) => ({
      ...prevState,
      type: val,
    }));
  };

  const handleEditButton = (val) => {
    setState((prevState) => ({
      ...prevState,
      editMode: val,
    }));
  };

  const handleSubmtButton = () => {
    const dataForSubmit = { venue: { ...textState } };
    delete dataForSubmit.pushNotificationsEnabled;
    delete dataForSubmit.venue.contactError;
    delete dataForSubmit.venue.emailError;
    delete dataForSubmit.venue.latitudeError;
    delete dataForSubmit.venue.longitudeError;
    delete dataForSubmit.venue.nameError;
    delete dataForSubmit.venue.phoneError;
    //delete dataForSubmit.venue.type;
    delete dataForSubmit.venue.pushNotificationsEnabled;
    dataForSubmit.venue.latitude = parseFloat(dataForSubmit.venue.latitude);
    dataForSubmit.venue.longitude = parseFloat(dataForSubmit.venue.longitude);

    //console.log(JSON.stringify(dataForSubmit));
    let errorsObj = {
      emailError: textState.email !== '' ? !validateEmail(textState.email) : false,
      phoneError: textState.phone !== '' ? !validatePhone(textState.phone) : false,
      latitudeError: !validateLatitude(textState.latitude),
      longitudeError: !validateLongitude(textState.longitude),
    };
    //console.log('validateLatLon(state.latitude): ' + validateLatitude(state.latitude));
    //console.log('validateLatLon(state.longitude): ' + validateLongitude(state.longitude));
    if (
      Object.values(errorsObj).every((item) => {
        return item === false;
      })
    ) {
      //everything Ok - send data
      //console.log('everything Ok - send data');
      setTextState((prevState) => ({
        ...prevState,
        emailError: false,
        phoneError: false,
        latitudeError: false,
        longitudeError: false,
      }));
      //console.log('Data for submit:');
      //console.log(JSON.stringify(dataForSubmit));

      updateVenueInfo(API_ENDPOINTS_LIST.venueUpdate, JSON.stringify(dataForSubmit), 'POST', true).then((res) => {
        //console.log('res:' + JSON.stringify(res));
        if (res && res.status === 200) {
          localStorage.setItem('loggedVenueData', JSON.stringify(res.venue));
          prepareData();

          Main.APP_PARAMS.setGlobalState((prevState) => ({
            ...prevState,
            toastOpen: true,
            toastMessage: `Venue Info successfully updated!`,
            toastStyle: 'success',
          }));
        } else {
          Main.APP_PARAMS.setGlobalState((prevState) => ({
            ...prevState,
            toastOpen: true,
            toastMessage: `Error! Can't add data to server. Please try again or contact us!`,
            toastStyle: 'error',
          }));
        }
      });
    } else {
      Main.APP_PARAMS.setGlobalState((prevState) => ({
        ...prevState,
        toastOpen: true,
        toastMessage: `Some fields is incorrect!`,
        toastStyle: 'error',
      }));
      setTextState((prevState) => ({
        ...prevState,
        emailError: errorsObj.emailError,
        phoneError: errorsObj.phoneError,
        latitudeError: errorsObj.latitudeError,
        longitudeError: errorsObj.longitudeError,
      }));
    }
  };

  function VenueDataItem({ name, val, keyVar }) {
    //console.log('name: ' + name + ' val: ' + val);
    return (
      <Grid key={keyVar} container direction="row">
        <Grid item xl={6} lg={6} md={6} sm={6} xs={6} style={getStyles().dataItem1}>
          <Typography style={{ ...commonStyles.text.robotoRegular18, color: commonStyles.color.firmGrey }}>
            {name}
          </Typography>
        </Grid>
        <Grid item align="left" xl={6} lg={6} md={6} sm={6} xs={6} style={getStyles().dataItem2}>
          <Typography style={{ ...commonStyles.text.robotoRegular18 }}>{String(val)}</Typography>
        </Grid>
      </Grid>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <HeaderComponent screenNumber={8} />
      <Paper style={getStyles().mainPaper} elevation={0}>
        <Grid container direction="row" style={getStyles().topTextGrid}>
          <Grid item xl={3} lg={3} md={3} sm={12} xs={12} style={getStyles().topTextGridColumn1}>
            <Grid item>
              <Typography noWrap style={commonStyles.text.robotoBold30}>
                Toxon App
              </Typography>
            </Grid>
            <Grid container direction="row" alignItems="center">
              <Grid item>
                <CardMedia component="img" image={archeryImage} style={getStyles().archeryImage} />
              </Grid>
              <Grid item>
                <Typography>Archery. Connected.</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xl={9} lg={9} md={9} sm={12} xs={12} style={getStyles().topTextGridColumn2}>
            <CardMedia component="img" image={headImage} style={getStyles().headImage} />
            <Grid container>
              <Grid item>
                <CardMedia component="img" image={logoImage} style={getStyles().logoImage} />
              </Grid>
            </Grid>
            <Grid item xs={12} align="center" position="relative">
              <Typography
                style={{
                  ...commonStyles.text.robotoRegular24,
                  ...{ marginTop: '70px' },
                }}
              >
                Venue Profile
              </Typography>
              {state.venueAdmin === true ? (
                <Typography style={{ ...commonStyles.text.robotoRegular18Light, color: 'red',...{ marginBottom: '30px' } }}>
                  You are logged in as Admin!
                </Typography>
              ) : null}
              <Typography style={{ ...commonStyles.text.robotoRegular18Light, ...{ marginBottom: '50px' } }}>
                What we know about your Venue
              </Typography>
              {state.venueData !== null && state.venueDynamicComponents.length > 0 && !state.editMode ? (
                state.venueDynamicComponents.map((component, index) => (
                  <React.Fragment key={index}>{component}</React.Fragment>
                ))
              ) : (
                <React.Fragment>
                  <Grid container direction="row">
                    <Grid item align="left" xl={12} lg={12} md={12} sm={12} xs={12} style={getStyles().dataItem1}>
                      <TextField
                        fullWidth
                        label={'Name'}
                        variant="outlined"
                        value={String(textState.name)}
                        error={textState.nameError}
                        onChange={(e) => {
                          updateText('name', e.target.value);
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container direction="row">
                    <Grid item align="left" xl={12} lg={12} md={12} sm={12} xs={12} style={getStyles().dataItem1}>
                      <TextField
                        fullWidth
                        label={'Contact name'}
                        variant="outlined"
                        value={String(textState.contact)}
                        error={textState.contactError}
                        onChange={(e) => {
                          updateText('contact', e.target.value);
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container direction="row">
                    <Grid item align="left" xl={12} lg={12} md={12} sm={12} xs={12} style={getStyles().dataItem1}>
                      <TextField
                        fullWidth
                        label={'Phone'}
                        variant="outlined"
                        value={String(textState.phone)}
                        error={textState.phoneError}
                        onChange={(e) => {
                          updateText('phone', e.target.value);
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container direction="row">
                    <Grid item align="left" xl={12} lg={12} md={12} sm={12} xs={12} style={getStyles().dataItem1}>
                      <TextField
                        fullWidth
                        label={'Email'}
                        variant="outlined"
                        type="email"
                        value={String(textState.email)}
                        error={textState.emailError}
                        onChange={(e) => {
                          updateText('email', e.target.value);
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container direction="row">
                    <Grid item align="left" xl={12} lg={12} md={12} sm={12} xs={12} style={getStyles().dataItem1}>
                      <FormControl component="fieldset">
                        <FormLabel component="legend">Type</FormLabel>
                        <RadioGroup
                          aria-label="type"
                          name="radio-buttons-group"
                          value={textState.type}
                          onChange={(e) => {
                            handleRadioButtonsChange(e.target.value);
                          }}
                        >
                          <FormControlLabel value="pro-Shop" control={<Radio color="secondary" />} label="Pro-Shop" />
                          <FormControlLabel value="club" control={<Radio color="secondary" />} label="Club" />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container direction="row">
                    <Grid item align="left" xl={12} lg={12} md={12} sm={12} xs={12} style={getStyles().dataItem1}>
                      <TextField
                        fullWidth
                        label={'Website'}
                        variant="outlined"
                        value={String(textState.website)}
                        //error={state.passwordError}
                        onChange={(e) => {
                          updateText('website', e.target.value);
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container direction="row">
                    <Grid item align="left" xl={12} lg={12} md={12} sm={12} xs={12} style={getStyles().dataItem1}>
                      <TextField
                        fullWidth
                        label={'Address'}
                        variant="outlined"
                        value={String(textState.address)}
                        //error={state.passwordError}
                        onChange={(e) => {
                          updateText('address', e.target.value);
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container direction="row">
                    <Grid item align="left" xl={12} lg={12} md={12} sm={12} xs={12} style={getStyles().dataItem1}>
                      <TextField
                        fullWidth
                        label={'Description'}
                        variant="outlined"
                        multiline={true}
                        value={String(textState.description)}
                        //error={state.passwordError}
                        onChange={(e) => {
                          updateText('description', e.target.value);
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container direction="row">
                    <Grid item align="left" xl={6} lg={6} md={6} sm={6} xs={6} style={getStyles().dataItem1}>
                      <TextField
                        fullWidth
                        label={'latitude'}
                        variant="outlined"
                        type="number"
                        value={String(textState.latitude)}
                        error={textState.latitudeError}
                        onChange={(e) => {
                          updateText('latitude', e.target.value);
                        }}
                      />
                    </Grid>
                    <Grid item align="left" xl={6} lg={6} md={6} sm={6} xs={6} style={getStyles().dataItem1}>
                      <TextField
                        fullWidth
                        label={'longitude'}
                        variant="outlined"
                        type="number"
                        value={String(textState.longitude)}
                        error={textState.longitudeError}
                        onChange={(e) => {
                          updateText('longitude', e.target.value);
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container direction="row">
                    <Grid item align="left" xl={12} lg={12} md={12} sm={12} xs={12} style={getStyles().dataItem1}>
                      <Typography style={{ ...commonStyles.text.robotoRegular18Light }}>
                        We use latitude and longitude to show your location on the map.
                      </Typography>
                      <Typography style={{ ...commonStyles.text.robotoRegular18Light }}>
                        Not sure what's your lat, lon coordinates? Use this free service:{' '}
                        <a
                          href="https://www.latlong.net/convert-address-to-lat-long.html"
                          target="_blank"
                          rel="noreferrer"
                        >
                          www.LatLong.net
                        </a>
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container direction="row">
                    <Grid item align="left" xl={12} lg={12} md={12} sm={12} xs={12} style={getStyles().dataItem1}>
                      <Typography style={{ ...commonStyles.text.robotoRegular16 }}>
                        - All fields are mandatory. Please fill all fields to submit updates!
                      </Typography>
                    </Grid>
                  </Grid>
                </React.Fragment>
              )}
            </Grid>
            <Grid item xs={12} align="center" style={{ marginTop: '70px' }}>
              {state.editMode ? (
                <React.Fragment>
                  <Button
                    variant="contained"
                    disabled={submitButtonState}
                    color="secondary"
                    style={{ color: commonStyles.color.white, marginRight: '20px' }}
                    onClick={() => {
                      handleSubmtButton();
                    }}
                  >
                    Submit
                  </Button>
                  <Button
                    variant="contained"
                    //disabled={submitButtonState}
                    color="secondary"
                    style={{ color: commonStyles.color.white }}
                    onClick={() => {
                      handleEditButton(false);
                    }}
                  >
                    Cancel
                  </Button>
                </React.Fragment>
              ) : (
                <Button
                  variant="contained"
                  //disabled={submitButtonState}
                  color="secondary"
                  style={{ color: commonStyles.color.white }}
                  onClick={() => {
                    handleEditButton(true);
                  }}
                >
                  Edit
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </ThemeProvider>
  );
}

const getStyles = (isMdScreen = false) => {
  const styles = {
    mainPaper: { marginTop: '100px' },
    archeryImage: {
      maxWidth: '40px',
    },
    topTextGrid: {
      marginTop: '70px',
      minHeight: '100px',
      maxWidth: '100%',
      //background: '#FFAA00',
    },
    topTextGridColumn1: {
      //background: '#DDFF99',
      padding: '30px',
    },
    topTextGridColumn2: {
      //background: '#99FF99',
      padding: '30px',
      userSelect: 'none',
    },
    headImage: {
      maxHeight: '250px',
    },
    logoImage: {
      position: 'absolute',
      maxWidth: '100px',
      marginTop: '-50px',
      marginLeft: '30px',
    },
    dataItem1: {
      //borderBottom: '1px solid',
      padding: '10px',
    },
    dataItem2: {
      borderBottom: '1px solid',
      padding: '10px',
    },
    buttonGroup: {
      gap: '10px',
      marginBottom: '30px',
      top: '22%',
      left: '-25%',
//      position: isMdScreen ? 'absolute' : 'unset',
    },
    infoButton: {
      borderRadius: '25px',
      width: '100%',
    },
  };
  return styles;
};
