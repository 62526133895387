import Main from '../main/Main';

export default function logOut() {
    Main.APP_PARAMS.userRole = 'user';
    Main.APP_PARAMS.setGlobalState((prevState) => ({
      ...prevState,
      toastOpen: true,
      toastMessage: `You are successfully logged out`,
      toastStyle: 'success',
      authToken: null,
    }));
    localStorage.removeItem('token');
    localStorage.removeItem('loggedVenueData');
    if (window.location.pathname !== '/') {
      window.location.href = '/';
    }
  };
