import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import {
  ThemeProvider,
  Grid,
  createTheme,
  Paper,
  CardMedia,
  Typography,
  Button,
  TextField,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import useServerRequest from "../../hooks/serverRequest";
import { API_ENDPOINTS_LIST } from "../../commonUtils/constants";
//import { ROUTES_LIST } from '../../commonUtils/constants';

import HeaderComponent from "../Components/HeaderComponent/HeaderComponent";

//import noImage from '../../assets/no_image_placeholder.png';
import logoImage from "../../assets/ToxonLogo.png";
import archeryImage from "../../assets/archery-icon.png";
import headImage from "../../assets/add_event_head.jpg";
import commonStyles from "../../commonUtils/commonStyles";
import { ROUTES_LIST } from "../../commonUtils/constants";
import logOut from "../../commonUtils/logout";

import {
  validateEmail,
  validatePhone,
  validateLatitude,
  validateLongitude,
} from "../../commonUtils/commonUtils";

import Main from "../../main/Main";

const theme = createTheme({
  palette: {
    secondary: {
      main: commonStyles.color.blue,
    },
    primary: {
      main: commonStyles.color.firmGrey,
    },
    action: {
      disabledBackground: commonStyles.color.blueDisabled,
      disabled: commonStyles.color.white,
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
});

const buttonsGroup = [
  {
    key: "button-1",
    text: "Add Event",
    link: ROUTES_LIST.addEvent,
  },
  {
    key: "button-2",
    text: "Create push notification",
    link: ROUTES_LIST.venuePushNotifications,
  },
  {
    key: "button-3",
    text: "Events",
    link: ROUTES_LIST.venueEvents,
  },
  {
    key: "button-4",
    text: "Venue profile",
    link: ROUTES_LIST.venuePersonalData,
  },
  {
    key: "button-5",
    text: "Logout",
    onClick: () => {
      logOut();
    },
  },
];

export default function Welcome() {
  const themeHook = useTheme();
  const isMdScreen = useMediaQuery(themeHook.breakpoints.up("md"));
  const navigate = useNavigate();
  const setGlobalLoading = (val) => {
    Main.APP_PARAMS.setGlobalState((prevState) => ({
      ...prevState,
      globalLoading: val,
    }));
  };

  const [state, setState] = useState({
    venueData: null,
    venueTypes: [],
    venueName: "",
    venueAdmin: false,
    venueDynamicComponents: [],
    editMode: false,
  });

  const [textState, setTextState] = useState({
    name: "",
    nameError: false,
    contact: "",
    contactError: false,
    phone: "",
    phoneError: false,
    email: "",
    emailError: false,
    type: "",
    website: "",
    address: "",
    description: "",
    latitude: 0,
    latitudeError: false,
    longitude: 0,
    longitudeError: false,
  });

  const [submitButtonState, setSubmitButtonState] = useState(false);

  //const { getRequest: loadInitialData } = useServerRequest(setGlobalLoading);
  const { getRequest: updateVenueInfo } = useServerRequest(setGlobalLoading);

  useEffect(() => {
    //console.log('Load initial data');
    prepareData();
  }, []);

  const prepareData = () => {
    const venueData = JSON.parse(localStorage.getItem("loggedVenueData"));
    const venueTypes = JSON.parse(
      localStorage.getItem("loggedVenueAvailableTypes")
    );
    //console.log('venueTypes: ' + JSON.stringify(venueTypes));
    let dynamicComponentsArray = [];
    Object.keys(venueData).forEach(function (key) {
      //console.log(key, state.venueData[key]);
      if (key !== "admin") {
        dynamicComponentsArray.push(
          <VenueDataItem name={key} val={venueData[key]} keyVar={key} />
        );
        updateText(key, venueData[key]);
      }
    });
    setState((prevState) => ({
      ...prevState,
      venueData: venueData,
      venueName: venueData.name,
      venueAdmin: venueData.admin,
      venueDynamicComponents: dynamicComponentsArray,
      editMode: false,
    }));
  };

  useEffect(() => {
    //call function when something change in state
    //check if all mandatory fields is not empty!
    let allFieldsFilled = false;
    let errorsObj = {
      nameError: textState.name === "" ? true : false,
      contactError: textState.contact === "" ? true : false,
      phoneError: textState.phone === "" ? true : false,
      //imageError: state.image === '' ? true : false,
      emailError: textState.email === "" ? true : false,
      latitudeError: textState.latitude === "" ? true : false,
      longitudeError: textState.longitude === "" ? true : false,
    };

    //console.log('A: ' + JSON.stringify(Object.values(errorsObj)));

    if (
      Object.values(errorsObj).every((item) => {
        return item === false;
      })
    ) {
      allFieldsFilled = true;
    } else {
      allFieldsFilled = false;
    }
    //console.log('allFieldsFilled: ' + allFieldsFilled);

    setSubmitButtonState(!allFieldsFilled);
  }, [textState]); //dependency added

  const updateText = (name, val) => {
    //console.log('Update text: ' + name + ' val: ' + val);
    setTextState((prevState) => ({
      ...prevState,
      [name]: val,
    }));
  };

  const handleRadioButtonsChange = (val) => {
    setTextState((prevState) => ({
      ...prevState,
      type: val,
    }));
  };

  const handleEditButton = (val) => {
    setState((prevState) => ({
      ...prevState,
      editMode: val,
    }));
  };

  const handleSubmtButton = () => {
    const dataForSubmit = { venue: { ...textState } };
    delete dataForSubmit.pushNotificationsEnabled;
    delete dataForSubmit.venue.contactError;
    delete dataForSubmit.venue.emailError;
    delete dataForSubmit.venue.latitudeError;
    delete dataForSubmit.venue.longitudeError;
    delete dataForSubmit.venue.nameError;
    delete dataForSubmit.venue.phoneError;
    //delete dataForSubmit.venue.type;
    delete dataForSubmit.venue.pushNotificationsEnabled;
    dataForSubmit.venue.latitude = parseFloat(dataForSubmit.venue.latitude);
    dataForSubmit.venue.longitude = parseFloat(dataForSubmit.venue.longitude);

    //console.log(JSON.stringify(dataForSubmit));
    let errorsObj = {
      emailError:
        textState.email !== "" ? !validateEmail(textState.email) : false,
      phoneError:
        textState.phone !== "" ? !validatePhone(textState.phone) : false,
      latitudeError: !validateLatitude(textState.latitude),
      longitudeError: !validateLongitude(textState.longitude),
    };
    //console.log('validateLatLon(state.latitude): ' + validateLatitude(state.latitude));
    //console.log('validateLatLon(state.longitude): ' + validateLongitude(state.longitude));
    if (
      Object.values(errorsObj).every((item) => {
        return item === false;
      })
    ) {
      //everything Ok - send data
      //console.log('everything Ok - send data');
      setTextState((prevState) => ({
        ...prevState,
        emailError: false,
        phoneError: false,
        latitudeError: false,
        longitudeError: false,
      }));
      //console.log('Data for submit:');
      //console.log(JSON.stringify(dataForSubmit));

      updateVenueInfo(
        API_ENDPOINTS_LIST.venueUpdate,
        JSON.stringify(dataForSubmit),
        "POST",
        true
      ).then((res) => {
        //console.log('res:' + JSON.stringify(res));
        if (res && res.status === 200) {
          localStorage.setItem("loggedVenueData", JSON.stringify(res.venue));
          prepareData();

          Main.APP_PARAMS.setGlobalState((prevState) => ({
            ...prevState,
            toastOpen: true,
            toastMessage: `Venue Info successfully updated!`,
            toastStyle: "success",
          }));
        } else {
          Main.APP_PARAMS.setGlobalState((prevState) => ({
            ...prevState,
            toastOpen: true,
            toastMessage: `Error! Can't add data to server. Please try again or contact us!`,
            toastStyle: "error",
          }));
        }
      });
    } else {
      Main.APP_PARAMS.setGlobalState((prevState) => ({
        ...prevState,
        toastOpen: true,
        toastMessage: `Some fields is incorrect!`,
        toastStyle: "error",
      }));
      setTextState((prevState) => ({
        ...prevState,
        emailError: errorsObj.emailError,
        phoneError: errorsObj.phoneError,
        latitudeError: errorsObj.latitudeError,
        longitudeError: errorsObj.longitudeError,
      }));
    }
  };

  function VenueDataItem({ name, val, keyVar }) {
    //console.log('name: ' + name + ' val: ' + val);
    return (
      <Grid key={keyVar} container direction="row">
        <Grid
          item
          xl={6}
          lg={6}
          md={6}
          sm={6}
          xs={6}
          style={getStyles().dataItem1}
        >
          <Typography
            style={{
              ...commonStyles.text.robotoRegular18,
              color: commonStyles.color.firmGrey,
            }}
          >
            {name}
          </Typography>
        </Grid>
        <Grid
          item
          align="left"
          xl={6}
          lg={6}
          md={6}
          sm={6}
          xs={6}
          style={getStyles().dataItem2}
        >
          <Typography style={{ ...commonStyles.text.robotoRegular18 }}>
            {String(val)}
          </Typography>
        </Grid>
      </Grid>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <HeaderComponent screenNumber={9} />
      <Paper style={getStyles().mainPaper} elevation={0}>
        <Grid container direction="row" style={getStyles().topTextGrid}>
          <Grid
            item
            xl={3}
            lg={3}
            md={3}
            sm={12}
            xs={12}
            style={getStyles().topTextGridColumn1}
          >
            <Grid item>
              <Typography noWrap style={commonStyles.text.robotoBold30}>
                Toxon App
              </Typography>
            </Grid>
            <Grid container direction="row" alignItems="center">
              <Grid item>
                <CardMedia
                  component="img"
                  image={archeryImage}
                  style={getStyles().archeryImage}
                />
              </Grid>
              <Grid item>
                <Typography>Archery. Connected.</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xl={9}
            lg={9}
            md={9}
            sm={12}
            xs={12}
            style={getStyles().topTextGridColumn2}
          >
            <CardMedia
              component="img"
              image={headImage}
              style={getStyles().headImage}
            />
            <Grid container>
              <Grid item>
                <CardMedia
                  component="img"
                  image={logoImage}
                  style={getStyles().logoImage}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} align="center" position="relative">
              <Grid
                container
                direction="column"
                sx={getStyles(isMdScreen).buttonGroup}
                xl={4}
                lg={4}
                md={5}
                sm={12}
                xs={12}
              >
                {buttonsGroup.map((button) => (
                  <Grid
                    item
                    key={button.key}
                    xl={3}
                    lg={3}
                    md={10}
                    sm={10}
                    xs={12}
                  >
                    <Button
                      key={button.key}
                      onClick={() =>
                        button.onClick
                          ? button.onClick()
                          : navigate(button.link)
                      }
                      color="info"
                      variant="contained"
                      style={getStyles().infoButton}
                    >
                      {button.text}
                    </Button>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </ThemeProvider>
  );
}

const getStyles = (isMdScreen = false) => {
  const styles = {
    mainPaper: { marginTop: "100px" },
    archeryImage: {
      maxWidth: "40px",
    },
    topTextGrid: {
      marginTop: "70px",
      minHeight: "100px",
      maxWidth: "100%",
      //background: '#FFAA00',
    },
    topTextGridColumn1: {
      //background: '#DDFF99',
      padding: "30px",
    },
    topTextGridColumn2: {
      //background: '#99FF99',
      padding: "30px",
      userSelect: "none",
    },
    headImage: {
      maxHeight: "250px",
    },
    logoImage: {
      position: "absolute",
      maxWidth: "100px",
      marginTop: "-50px",
      marginLeft: "30px",
    },
    dataItem1: {
      //borderBottom: '1px solid',
      padding: "10px",
    },
    dataItem2: {
      borderBottom: "1px solid",
      padding: "10px",
    },
    buttonGroup: {
      gap: "10px",
      marginBottom: "30px",
      marginTop: "30px",
      top: "22%",
      left: "-25%",
      //      position: isMdScreen ? 'absolute' : 'unset',
    },
    infoButton: {
      borderRadius: "25px",
      width: "100%",
    },
  };
  return styles;
};
