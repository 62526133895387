import React from 'react';
import commonStyles from '../../../commonUtils/commonStyles';
import { Button, styled } from '@mui/material';

const ButtonWrap = styled(({ fullWidth, width, color, variant = 'contained', disabled = false, ...other }) => (
  <Button {...other} />
))({
  width: (props) => (props.fullWidth ? '100%' : 'inherit'),
  minWidth: (props) => props.width || 106,
  fontWeight: 600,
  fontSize: '16px',
  padding: '2px',
  textTransform: 'none',
  color: (props) =>
    props.variant === 'contained' && !props.disabled ? commonStyles.color.white : commonStyles.color.blue,
  backgroundColor: (props) => (props.variant === 'contained' && !props.disabled ? commonStyles.color.blue : undefined),
  borderColor: (props) => (props.variant === 'outlined' && !props.disabled ? commonStyles.color.blue : undefined),
  '&:hover': {
    backgroundColor: (props) =>
      props.variant === 'contained' && !props.disabled ? commonStyles.color.blue : undefined,
  },
});

export default function TableButton({ onClick, children, variant, disabled, ...restProps }) {
  return (
    <ButtonWrap variant={variant} onClick={onClick} {...restProps} disabled={disabled}>
      {children}
    </ButtonWrap>
  );
}
