import React, { useState, useEffect } from 'react';

import { ThemeProvider, Grid, createTheme, Typography, TextField, Button } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';

import PNHistoryTableComponent from './PNHIstoryTableComponent';

import commonStyles from '../../../commonUtils/commonStyles';

import Main from '../../../main/Main';

const theme = createTheme({
  palette: {
    secondary: {
      main: commonStyles.color.blue,
    },
    primary: {
      main: commonStyles.color.firmGrey,
    },
    action: {
      disabledBackground: commonStyles.color.blueDisabled,
      disabled: commonStyles.color.white,
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
});

export default function PNHistoryMainComponent({
  isAdmin,
  venuesListData,
  pnListData,
  updatePNForSelectedVenue,
  changeVenuePNPermission,
}) {
  const [venuesState, setVenuesState] = useState([]);

  const [venueSubmitButtonState, setVenueSubmitButtonState] = useState(true);

  const [state, setState] = useState({
    isAdmin: false,
    selectedVenue: '',
    selectedVenueId: 0,
    selectedVenuePNenabled: false,
    pnList: [],
  });

  //const { getRequest: loadInitialData } = useServerRequest(setGlobalLoading);
  //const { getRequest: uploadData } = useServerRequest(setGlobalLoading);

  useEffect(() => {
    //console.log('Initial data: ');
    //const venueData = JSON.parse(localStorage.getItem('loggedVenueData'));
    if (venuesListData.length !== 0 && pnListData.length !== 0) {
      //console.log('venuesListData: ' + JSON.stringify(venuesListData));
      setState((prevState) => ({
        ...prevState,
        isAdmin: isAdmin,
        pnList: pnListData,
      }));
      setVenuesState(venuesListData);
    }
  }, [venuesListData, isAdmin, pnListData]);

  useEffect(() => {
    //call function when something change in state
    //console.log('state.selectedVenueId: ' + JSON.stringify(state.selectedVenueId));
    if (state.selectedVenueId !== 0) {
      setVenueSubmitButtonState(false);
      /*
      setState((prevState) => ({
        ...prevState,
        selectedVenueId: 0,
        selectedVenuePNenabled: false,
      }));
      */
    }
  }, [state.selectedVenueId]); //dependency added

  const updateText = (name, val) => {
    setState((prevState) => ({
      ...prevState,
      [name]: val,
    }));
  };

  const handleVenueSubmitButton = () => {
    /*
    console.log('Submit button pressed');
    console.log(
      'Venue name: ' +
        state.selectedVenue +
        ' VenueID: ' +
        state.selectedVenueId +
        ' enable PN? ' +
        state.selectedVenuePNenabled
    );
    */
    updatePNForSelectedVenue(state.selectedVenueId);
  };

  const handleVenuePNpermissionButton = () => {
    /*
    console.log(
      'Enable venue PN permission. Venue name: ' +
        state.selectedVenue +
        ' VenueID: ' +
        state.selectedVenueId +
        ' enable PN? ' +
        state.selectedVenuePNenabled
    );
    */
    if (state.selectedVenuePNenabled) {
      //ask for disable

      Main.APP_PARAMS.setGlobalState((prevState) => ({
        ...prevState,
        globalDialog: true,
        globalDialogData: {
          title: 'Confirm change in Push Notifications permission',
          message: (
            <div>
              <span style={{ color: 'DimGrey' }}>Are you sure you want to disable Push Notifications for Venue: </span>
              <br />
              <span style={{ color: 'red' }}>{state.selectedVenue}</span>
              <br />
              <span style={{ color: 'DimGrey' }}> VenueID: </span>
              <br />
              <span style={{ color: 'red' }}>{state.selectedVenueId}</span>
              <br />
              <span style={{ color: 'DimGrey' }}> Venue Push Notifications currently </span>
              <br />
              <span style={{ color: 'red' }}>{'\n'}enabled</span>
            </div>
          ),
          buttonOK: 'OK',
          buttonDecline: 'Decline',
        },
        globalDialogHandleClose: () => handleDialogConfirm(false),
      }));
    } else {
      //ask for enable
      Main.APP_PARAMS.setGlobalState((prevState) => ({
        ...prevState,
        globalDialog: true,
        globalDialogData: {
          title: 'Confirm change in Push Notifications permission',
          message: (
            <div>
              <span style={{ color: 'DimGrey' }}>Are you sure you want to enable Push Notifications for Venue: </span>
              <br />
              <span style={{ color: 'red' }}>{state.selectedVenue}</span>
              <br />
              <span style={{ color: 'DimGrey' }}> VenueID: </span>
              <br />
              <span style={{ color: 'red' }}>{state.selectedVenueId}</span>
              <br />
              <span style={{ color: 'DimGrey' }}> Venue Push Notifications currently </span>
              <br />
              <span style={{ color: 'red' }}>{'\n'}disabled</span>
            </div>
          ),
          buttonOK: 'OK',
          buttonDecline: 'Decline',
        },
        globalDialogHandleClose: () => handleDialogConfirm(true),
      }));
    }
  };

  const handleDialogConfirm = (enable) => {
    //console.log('handleDialogConfirm option: ' + enable);
    Main.APP_PARAMS.setGlobalState((prevState) => ({
      ...prevState,
      globalDialog: false,
      globalDialogData: {
        title: 'Title',
        message: 'Message',
        buttonOK: 'OK',
        buttonDecline: 'Decline',
      },
      globalDialogHandleClose: (param) => {},
    }));
    changeVenuePNPermission(state.selectedVenueId, enable);
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid container direction="row" style={getStyles().topTextGrid}>
        <Grid item xs={12} align="center">
          <Typography
            style={{
              ...commonStyles.text.robotoRegular24,
            }}
          >
            Push Notifications History
          </Typography>
        </Grid>
        {isAdmin ? (
          <Grid item xs={12} align="center">
            <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
              <Grid container direction="row">
                <Grid item xs={8}>
                  <Autocomplete
                    //fullWidth
                    id="host-select"
                    options={venuesState}
                    renderOption={(props, option) => {
                      return (
                        <span
                          {...props}
                          key={option.id}
                          style={{ color: option.pushNotificationsEnabled ? 'green' : 'red' }}
                        >
                          {option.name}
                        </span>
                      );
                    }}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => <TextField {...params} label="select venue" variant="outlined" />}
                    onChange={(event, value) => {
                      updateText('selectedVenue', value ? value.name : '');
                      setState((prevState) => ({
                        ...prevState,
                        selectedVenueId: value ? value.id : 0,
                        selectedVenuePNenabled: value ? value.pushNotificationsEnabled : 0,
                      }));
                    }}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Grid container alignItems="center" justifyContent="center" style={{ height: '100%' }}>
                    <Grid item>
                      <Button
                        variant="contained"
                        disabled={venueSubmitButtonState}
                        color="secondary"
                        style={{ color: commonStyles.color.white }}
                        onClick={() => {
                          handleVenueSubmitButton();
                        }}
                      >
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={2}>
                  <Grid container alignItems="center" justifyContent="center" style={{ height: '100%' }}>
                    <Grid item>
                      <Button
                        variant="contained"
                        disabled={venueSubmitButtonState}
                        color="secondary"
                        style={{ color: commonStyles.color.white }}
                        onClick={() => {
                          handleVenuePNpermissionButton();
                        }}
                      >
                        {state.selectedVenuePNenabled ? 'Disable' : 'Enable'}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
              <Grid item xs={8}>
                <Typography style={{ ...commonStyles.text.robotoRegular16 }}>
                  <span style={getStyles().redText}>*</span> <span style={getStyles().greenText}>Green:</span> Push
                  Notifications enabled. <span style={getStyles().redText}>Red:</span> Push Notifications disabled
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        ) : null}
        <Grid item xs={12} align="center">
          <PNHistoryTableComponent dataForTable={state.pnList} />
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}

const getStyles = () => {
  const styles = {
    topTextGrid: {
      marginTop: '70px',
      minHeight: '100px',
      maxWidth: '100%',
      //background: '#FFAA00',
    },
    topTextGridColumn: {
      //background: '#99FF99',
      padding: '30px',
      userSelect: 'none',
    },
    redText: { color: 'red' },
    greenText: { color: 'green' },
  };
  return styles;
};
