import React, { useState, useEffect } from 'react';

import { ThemeProvider, Grid, createTheme, Paper, CardMedia, Typography, Button, ButtonGroup, Link } from '@mui/material';

import { useNavigate } from 'react-router-dom';

import HeaderComponent from '../Components/HeaderComponent/HeaderComponent';

import logoImage from '../../assets/ToxonLogo.png';
import headerAlligator from '../../assets/header-alligator.jpg';
import headerGirl from '../../assets/header-girl.jpg';
import headerTargets from '../../assets/header-targets.jpg';
import appStoreImage from '../../assets/app-store.png';
import googlePlayImage from '../../assets/google-play.png';
import archeryImage from '../../assets/archery-icon.png';
import previewImage1 from '../../assets/ToxonImage1_iphone_11.jpg';
import previewImage2 from '../../assets/ToxonImage2_iphone_11.jpg';
import commonStyles from '../../commonUtils/commonStyles';

import Main from '../../main/Main';

import { ROUTES_LIST } from '../../commonUtils/constants';

const headerImages = [
  {
    key: 'header-image-1',
    image: headerAlligator,
    text: 'Club Portal',
    link: ROUTES_LIST.venueLogin
  },
  {
    key: 'header-image-2',
    image: headerTargets,
    text: 'Pro-Shop Portal',
    link: ROUTES_LIST.venueLogin
  },
  {
    key: 'header-image-3',
    image: headerGirl,
  },
];

const theme = createTheme({
  palette: {
    secondary: {
      main: commonStyles.color.authError,
    },
    primary: {
      main: commonStyles.color.firmGrey,
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
});

export default function HomeScreen() {
  const [isAuth, setIsAuth] = useState(false);
  const navigate = useNavigate();
  /*
  //get and update current window width
  const [width, setWidth] = useState(window.innerWidth);

  const handleResizeWindow = () => {
    //console.log('handleResizeWindow: ' + window.innerWidth);
    setWidth(window.innerWidth);
    if (window.innerWidth < 300) {
    }
  };

  useEffect(() => {
    handleResizeWindow();
    window.addEventListener('resize', handleResizeWindow);
  }, [width]);
  */

  useEffect(() => {
    if (Main.APP_PARAMS.globalState.authToken !== null) {
      setIsAuth(true);
    }
  }, []);

  const openInNewTab = (url) => {
    window.open(url, '_blank');
  };

  return (
    <ThemeProvider theme={theme}>
      <HeaderComponent screenNumber={0} />
      <Paper style={getStyles().mainPaper}>
        <Grid container direction="row" alignItems="center">
          {headerImages.map((headerImage) => (
            <Grid key={headerImage.key} item xl={4} lg={4} md={4} sm={12} xs={12} style={getStyles().headerImageGridItem}>
              <CardMedia component="img" src={headerImage.image} style={getStyles().mainImage} />
              <Link
                onClick={() => {
                  if (!isAuth) {
                    navigate(headerImage.link);
                  }
                }}
                underline={isAuth ? 'none' : 'hover'}
                variant='button'
                style={{
                  color: commonStyles.color.white,
                  ...commonStyles.text.robotoBold40,
                  ...getStyles(isAuth).headerImageCardDescription
                }}
              >
                {headerImage.text}
              </Link>
            </Grid>
          ))}
        </Grid>
        <Grid container>
          <Grid item>
            <CardMedia component="img" image={logoImage} style={getStyles().logoImage} />
          </Grid>
        </Grid>
        <Grid container direction="row" style={getStyles().topTextGrid}>
          <Grid item xl={3} lg={3} md={3} sm={12} xs={12} style={getStyles().topTextGridColumn1}>
            <Grid item>
              <Typography noWrap style={commonStyles.text.robotoBold30}>
                Toxon App
              </Typography>
            </Grid>
            <Grid container direction="row" alignItems="center">
              <Grid item>
                <CardMedia component="img" image={archeryImage} style={getStyles().archeryImage} />
              </Grid>
              <Grid item>
                <Typography>Archery. Connected.</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12} style={getStyles().topTextGridColumn2}>
            <Typography noWrap style={commonStyles.text.robotoRegular20bold}>
              Mission Statement:
            </Typography>
            <Typography display="inline">
              The Toxon App connects Archers and Archery Venues by providing an up-to-date platform
              to share and receive information, for the continued growth and passion of Archery.
              <br />
              <br />
            </Typography>
            <Typography noWrap style={commonStyles.text.robotoRegular20bold}>
              For Archers:
            </Typography>
            <Typography display="inline">
              Toxon is all about you shooting your bow more often. We connect the dots between archers and
              Pro-shops/Archery clubs so that it's easy for you to get out and shoot.
              <br />
              <br />
              Easily search and filter upcoming events and view them on a map. Find exactly what you're looking for
              right away.
              <br />
              <br />
              With Toxon you'll discover archery locations you didn't know existed! Don't miss out!
              <br />
              <br />
            </Typography>
            <Typography noWrap style={commonStyles.text.robotoRegular20bold}>
              For Clubs/Shops:
            </Typography>
            <Typography display="inline">
              The Toxon App was created to connect your club/shop to those actively seeking archery events.
              <br />
              <br />
              Simply register your club/shop and then add your events to the app for everyone to see.
              <br />
              <br />
              The app provides driving directions, link to website, event specific info, ability to save to calendar,
              push notifications, and much more.
            </Typography>
          </Grid>
          <Grid item xl={3} lg={3} md={3} sm={12} xs={12} align="center" style={getStyles().topTextGridColumn3}>
            <Grid item>
              <Button>
                <CardMedia
                  component="img"
                  image={appStoreImage}
                  onClick={() => {
                    openInNewTab('https://apps.apple.com/us/app/toxon/id1552051934');
                  }}
                  style={{ ...getStyles().storeImage }}
                />
              </Button>
            </Grid>
            <Grid item>
              <Button>
                <CardMedia
                  component="img"
                  image={googlePlayImage}
                  onClick={() => {
                    openInNewTab('https://play.google.com/store/apps/details?id=com.toxon');
                  }}
                  style={getStyles().storeImage}
                />
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                style={getStyles().registerButton}
                onClick={() => {
                  window.location.href = 'https://toxon-bucket.s3.us-east-2.amazonaws.com/app-release.apk';
                }}
              >
                <Typography noWrap style={commonStyles.text.robotoRegular20bold}>
                  Android App
                </Typography>
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                style={getStyles().registerButton}
                onClick={() => {
                  navigate(ROUTES_LIST.registerVenue);
                }}
              >
                <Typography noWrap style={commonStyles.text.robotoRegular20bold}>
                  Register Here
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid container direction="row" alignItems='center' justifyContent='center' style={getStyles().screenshotsGrid}>
          <Grid item xl={3} lg={3} md={3} sm={6} xs={6} align="center" style={getStyles().topTextGridColumn1}>
            <CardMedia component="img" image={previewImage1} style={getStyles().previewImage} />
          </Grid>
          <Grid item xl={3} lg={3} md={3} sm={6} xs={6} style={getStyles().topTextGridColumn1}>
            <Typography noWrap style={commonStyles.text.robotoRegular20bold}>
              Be found
            </Typography>
            <Typography display="inline">
              The Toxon App makes it easy for your club or pro shop to be found with a click of a button.
            </Typography>
          </Grid>
        </Grid>
        <Grid container direction="row" alignItems='center' justifyContent='center' style={getStyles().screenshotsGrid}>
          <Grid item xl={3} lg={3} md={3} sm={6} xs={6} style={getStyles().topTextGridColumn1}>
            <Typography noWrap style={commonStyles.text.robotoRegular20bold}>
              Get them there
            </Typography>
            <Typography display="inline">
              By providing the right information, you have the highest chance of archers attending your event.
            </Typography>
          </Grid>
          <Grid item xl={3} lg={3} md={3} sm={6} xs={6} align="center" style={getStyles().topTextGridColumn1}>
            <CardMedia component="img" image={previewImage2} style={getStyles().previewImage} />
          </Grid>
        </Grid>

        <Grid container style={getStyles().footerGrid}>
          <Grid item xs={12} align="center" style={getStyles().footerGridItem}>
            <Typography noWrap style={commonStyles.text.robotoRegular16}>
              Copyright &copy; {new Date().getFullYear()} Toxon
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </ThemeProvider>
  );
}

const getStyles = (isAuth = false) => {
  const styles = {
    mainPaper: { marginTop: '100px' },
    mainImage: {
      maxHeight: '350px',
    },
    logoImage: {
      position: 'absolute',
      maxWidth: '100px',
      marginTop: '-50px',
      marginLeft: '30px',
    },
    storeImage: {
      maxWidth: '180px',
      //marginTop: '20px',
    },
    archeryImage: {
      maxWidth: '40px',
    },
    previewImage: {
      borderRadius: '10px',
      maxWidth: '400px',
    },
    topTextGrid: {
      marginTop: '70px',
      minHeight: '100px',
      maxWidth: '100%',
      //background: '#FFAA00',
    },
    topTextGridColumn1: {
      //background: '#DDFF99',
      padding: '30px',
    },
    topTextGridColumn2: {
      //background: '#99FF99',
      padding: '30px',
    },
    topTextGridColumn3: {
      //background: '#DD9999',
      padding: '30px',
    },
    screenshotsGrid: {
      marginTop: '10px',
      minHeight: '100px',
      maxWidth: '100%',
      //background: '#FF8800',
    },
    pricingGrid: {
      marginLeft: '30px',
      paddingTop: '20px',
      paddingBottom: '20px',
      //background: '#998800',
    },
    tableGrid: {
      marginTop: '10px',
      minHeight: '100px',
      //background: '#998800',
    },
    footerGrid: {
      marginTop: '10px',
      maxWidth: '100%',
      //background: '#998800',
    },
    footerGridItem: {
      //background: '#ddeeaa',
      paddingTop: '50px',
      paddingBottom: '50px',
    },
    registerButton: {
      width: '180px',
      height: '60px',
      marginTop: '10px',
      alignItems: 'center',
      justifyContent: 'center',
    },
    headerImageGridItem: {
      position: 'relative',
    },
    headerImageCardDescription: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      textAlign: 'center',
      textShadow: '2px 2px 4px rgb(0, 0, 0)',
      lineHeight: '140%',
      cursor: isAuth ? 'default' : 'pointer',
    },
  };
  return styles;
};
