import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';

import {
  Drawer,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  IconButton,
  Typography,
  Toolbar,
  CardMedia,
  Box,
  Menu,
  MenuItem,
  AppBar,
} from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MenuIcon from '@mui/icons-material/Menu';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import HomeIcon from '@mui/icons-material/Home';
import ContactSupportIcon from '@mui/icons-material/Mail';
import CompanyAddIcon from '@mui/icons-material/AssignmentTurnedIn';
import PrivacyPolicyIcon from '@mui/icons-material/Description';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import BarChartIcon from '@mui/icons-material/BarChart';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { useTheme } from '@mui/material/styles';

import Main from '../../../main/Main';
import logOut from '../../../commonUtils/logout';

import logo from '../../../assets/ToxonLogo.png';
import commonStyles from '../../../commonUtils/commonStyles';
import { ROUTES_LIST } from '../../../commonUtils/constants';

let drawerWidth = 240;
let theme;

function HeaderComponent(props) {
  const [width, setWidth] = useState(window.innerWidth);
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);

  const openUserMenu = Boolean(anchorEl);

  const handleResizeWindow = () => {
    //console.log('handleResizeWindow: ' + window.innerWidth);
    setWidth(window.innerWidth);
    if (window.innerWidth < 300) {
      drawerWidth = window.innerWidth - 60;
    }
  };

  useEffect(() => {
    handleResizeWindow();
    window.addEventListener('resize', handleResizeWindow);
  }, [width]);

  theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const navigate = useNavigate();

  const userRole = Main.APP_PARAMS.userRole;

  useEffect(() => {
    if (Main.APP_PARAMS.globalState.authToken !== null) {
      setCurrentUser('loggedVenue');
    }
  }, [Main.APP_PARAMS.globalState.authToken]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleBackButton = () => {
    navigate(-1);
  };

  const LeftHeaderButton = () => {
    switch (props.leftButtonType) {
      case 'ArrowBack':
        return (
          <IconButton color="inherit" onClick={handleBackButton} edge="start" style={getStyles().menuButton}>
            <ArrowBackIcon />
          </IconButton>
        );
      default:
        return (
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            //className={clsx(getStyles().menuButton, open && getStyles().hide)}
            style={open === false ? getStyles().menuButton : getStyles().hide}
          >
            <MenuIcon />
          </IconButton>
        );
    }
  };

  const menuList = {
    line1: [
      {
        title: Main.getScreenNames()[0],
        icon: <HomeIcon style={getStyles().listIcon} />,
        link: ROUTES_LIST.home,
      },
      {
        title: Main.getScreenNames()[4],
        icon: <CompanyAddIcon style={getStyles().listIcon} />,
        link: ROUTES_LIST.registerVenue,
      },
      {
        title: Main.getScreenNames()[3],
        icon: <ContactSupportIcon style={getStyles().listIcon} />,
        link: ROUTES_LIST.contactUs,
      },
      {
        title: Main.getScreenNames()[1],
        icon: <PrivacyPolicyIcon style={getStyles().listIcon} />,
        link: ROUTES_LIST.privacyPolicy,
      },
      {
        title: Main.getScreenNames()[2],
        icon: <PrivacyPolicyIcon style={getStyles().listIcon} />,
        link: ROUTES_LIST.eula,
      },
    ],
  };

  const menuListContent = Object.keys(menuList).map((key, i) => {
    const isLast = Object.keys(menuList).length === i + 1;

    return (
      <List key={i} component="nav">
        {menuList[key].map(({ title, icon, link, role }, index) => {
          if (link) {
            return (
              <Link to={link} key={`${key}-${index}`} style={getStyles().listLink}>
                <ListItem button>
                  <ListItemIcon>{icon}</ListItemIcon>
                  <ListItemText primary={title} style={commonStyles.text.robotoRegular16} />
                </ListItem>
              </Link>
            );
          }
          return (
            <ListItem button key={`${key}-${index}`}>
              <ListItemIcon>{icon}</ListItemIcon>
              <ListItemText primary={title} style={commonStyles.text.robotoRegular16} />
            </ListItem>
          );
        })}
        {!isLast && <Divider />}
      </List>
    );
  });

  return (
    <AppBar
      position="fixed"
      style={{
        ...getStyles().topAppBar,
        ...{ height: '100px' },
      }}
    >
      <Grid container direction="row" alignItems="center">
        <Grid item>
          <CardMedia component="img" style={getStyles().logoImage} image={logo} />
        </Grid>
        <Grid item>
          <Typography noWrap style={getStyles().logoText}>
            Toxon App
          </Typography>
        </Grid>
      </Grid>
      <AppBar elevation={5} position="static" style={open === false ? getStyles().appBar : getStyles().appBarShift}>
        <Toolbar style={getStyles().topToolbar}>
          <LeftHeaderButton />
          <Typography noWrap style={commonStyles.text.robotoRegular24}>
            {Main.getScreenNames()[props.screenNumber]}
          </Typography>
          <Box style={getStyles().rightSideButtonsContainer}>
            <IconButton
              aria-label="account of current venue"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={(event) => {
                //console.log(event.currentTarget);
                setAnchorEl(event.currentTarget);
              }}
              color="inherit"
            >
              <AccountCircleIcon />
            </IconButton>

            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={openUserMenu}
              onClose={() => {
                setAnchorEl(null);
              }}
              MenuListProps={{
                style: { width: '250px' },
              }}
            >
              {currentUser === 'loggedVenue' ? (
                <Box>
                  <MenuItem
                    onClick={() => {
                      setAnchorEl(null);
                      navigate(ROUTES_LIST.welcome);
                    }}
                  >
                    <BarChartIcon
                      style={{
                        color: commonStyles.color.blue,
                        fontSize: '25px',
                        marginRight: '15px',
                      }}
                    />
                    <Typography style={commonStyles.text.robotoRegular16}>Venue personal area</Typography>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setAnchorEl(null);
                      logOut();
                    }}
                  >
                    <ExitToAppIcon
                      style={{
                        color: commonStyles.color.blue,
                        fontSize: '25px',
                        marginRight: '15px',
                      }}
                    />
                    <Typography style={commonStyles.text.robotoRegular16}>Exit</Typography>
                  </MenuItem>
                </Box>
              ) : (
                <Box>
                  <MenuItem
                    onClick={() => {
                      setAnchorEl(null);
                      navigate(ROUTES_LIST.venueLogin);
                    }}
                  >
                    <LockOpenIcon
                      style={{
                        color: commonStyles.color.blue,
                        fontSize: '25px',
                        marginRight: '15px',
                      }}
                    />
                    <Typography style={commonStyles.text.robotoRegular16}>Venue Login</Typography>
                  </MenuItem>
                </Box>
              )}
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer variant="persistent" anchor="left" open={open} PaperProps={{ style: { ...getStyles().drawerPaper } }}>
        <div style={getStyles().drawerHeader}>
          <Divider />
          <IconButton onClick={handleDrawerClose}>
            {open === true ? (
              <ChevronLeftIcon
                style={{
                  color: commonStyles.color.firmGrey,
                  fontSize: '35px',
                }}
              />
            ) : (
              <ChevronRightIcon style={{ color: commonStyles.color.firmGrey, fontSize: '35px' }} />
            )}
          </IconButton>
        </div>
        <Divider />
        {menuListContent}
      </Drawer>
    </AppBar>
  );
}

const getStyles = () => {
  const styles = {
    topAppBar: {
      top: '0',
      bottom: 'auto',
      background: commonStyles.color.white,
    },
    topToolbar: {
      background: commonStyles.color.firmGrey,
      height: '55px',
    },
    logoImage: {
      marginTop: '5px',
      marginBottom: '5px',
      marginLeft: '10px',
      width: '35px',
      objectFit: 'initial',
    },
    logoText: {
      ...commonStyles.text.robotoRegular18,
      ...{ color: commonStyles.color.backgroundDark, marginLeft: '10px' },
    },
    appBar: {
      height: '55px',
      //background: commonStyles.color.firmGrey,
      //marginTop: '20px',
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      height: '55px',
      //background: commonStyles.color.firmGrey,
      width: `calc(100% - ${drawerWidth}px)`,
      //marginTop: '20px',
      marginLeft: drawerWidth,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    hide: {
      display: 'none',
    },
    drawerPaper: {
      width: `${drawerWidth}px`,
      // height: '430px',
      //marginTop: '84px',
      border: '0px solid',
      borderTop: '1px solid',
      borderColor: 'rgba(0, 0, 0, 0.12)',
      boxShadow: '0px 5.5px 5px rgba(0,0,0,0.24), 0px 9px 18px rgba(0,0,0,0.18)',
    },
    drawerHeader: {
      display: 'flex',
      height: '45px',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      //...theme.mixins.toolbar,
      justifyContent: 'flex-end',
    },
    listIcon: {
      color: commonStyles.color.firmGrey,
      fontSize: '25px',
    },
    listLink: {
      textDecoration: 'none',
      color: 'inherit',
    },
    rightSideButtonsContainer: {
      display: 'flex',
      flexDirection: 'row',
      height: '65px',
      marginLeft: 'auto',
      alignItems: 'center',
      //border: '1px solid',
      //borderColor: 'red',
    },
  };
  return styles;
};

export default HeaderComponent;
