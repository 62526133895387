import React, { useState } from 'react';

import { Grid, Typography, TextField } from '@mui/material';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';

import commonStyles from '../../../commonUtils/commonStyles';

//const moment = require('moment');

export default function EventDateAndTime({ index, startDate, endDate, setStartDateTime, setEndDateTime }) {
  //const [selectedDate, handleDateChange] = useState(null);
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Grid item xs={12} align="center" style={{ ...getStyles().textHeader }}>
        <Grid container justifyContent="center">
          <Grid
            item
            xs={12}
            sm={12}
            md={8}
            lg={8}
            xl={8}
            style={{ width: '100%', justifyContent: 'flex-start', textAlign: 'start' }}
          >
            <Typography style={{ ...commonStyles.text.robotoRegular20bold }}>
              #{index + 1} Start Date/Time <span style={getStyles().redText}>*</span>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} align="center">
        <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
          <MobileDateTimePicker
            label="DD/MM/YYYY hh:mm"
            inputFormat="DD/MM/YYYY hh:mm A"
            value={dayjs(startDate)}
            onChange={(date) => {
              //console.log('DateTime: ' + dayjs(date).format('YYYY-MM-DD HH:mm'));
              const formattedDateTime = dayjs(date).format('YYYY-MM-DD HH:mm');
              //console.log(JSON.stringify(formattedDateTime));
              setStartDateTime(index, formattedDateTime);
            }}
            renderInput={(params) => <TextField {...params} fullWidth />}
          />
          {/*<Typography style={{ ...commonStyles.text.robotoRegular20bold }}>Here should be DateTimePicker</Typography>*/}
          {/*<DateTimePicker
            fullWidth
            helperText="dd/mm/yyyy hh:mm"
            value={startDate}
            format="DD/MM/yyyy hh:mm A"
            onChange={(date) => {
              const formattedDateTime = moment(date).format('YYYY-MM-DD HH:mm');
              //console.log(JSON.stringify(formattedDateTime));
              setStartDateTime(index, formattedDateTime);
            }}
          />*/}
        </Grid>
      </Grid>

      <Grid item xs={12} align="center" style={{ ...getStyles().textHeader }}>
        <Grid container justifyContent="center">
          <Grid
            item
            xs={12}
            sm={12}
            md={8}
            lg={8}
            xl={8}
            style={{ width: '100%', justifyContent: 'flex-start', textAlign: 'start' }}
          >
            <Typography style={{ ...commonStyles.text.robotoRegular20bold }}>
              #{index + 1} End Date/Time <span style={getStyles().redText}>*</span>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} align="center">
        <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
          <MobileDateTimePicker
            label="DD/MM/YYYY hh:mm"
            inputFormat="DD/MM/YYYY hh:mm A"
            value={dayjs(endDate)}
            onChange={(date) => {
              //console.log('DateTime: ' + dayjs(date).format('YYYY-MM-DD HH:mm'));
              const formattedDateTime = dayjs(date).format('YYYY-MM-DD HH:mm');
              //console.log(JSON.stringify(formattedDateTime));
              setEndDateTime(index, formattedDateTime);
            }}
            renderInput={(params) => <TextField {...params} fullWidth />}
          />
          {/*<Typography style={{ ...commonStyles.text.robotoRegular20bold }}>Here should be DateTimePicker</Typography>*/}
          {/*<DateTimePicker
            fullWidth
            helperText="dd/mm/yyyy hh:mm"
            value={endDate}
            format="DD/MM/yyyy hh:mm A"
            onChange={(date) => {
              const formattedDateTime = moment(date).format('YYYY-MM-DD HH:mm');
              //console.log(JSON.stringify(formattedDateTime));
              setEndDateTime(index, formattedDateTime);
            }}
          />*/}
        </Grid>
      </Grid>
    </LocalizationProvider>
  );
}

const getStyles = () => {
  const styles = {
    textHeader: { marginTop: '30px', marginBottom: '30px' },
    redText: { color: '#FF0000' },
  };
  return styles;
};
