import React, { useState, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import { ThemeProvider, Grid, createTheme, Paper, CardMedia, Typography, TextField, Button } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import useServerRequest from '../../hooks/serverRequest';
import { API_ENDPOINTS_LIST } from '../../commonUtils/constants';
import { ROUTES_LIST } from '../../commonUtils/constants';

import HeaderComponent from '../Components/HeaderComponent/HeaderComponent';

//import noImage from '../../assets/no_image_placeholder.png';
import logoImage from '../../assets/ToxonLogo.png';
import archeryImage from '../../assets/archery-icon.png';
import headImage from '../../assets/add_event_head.jpg';
import commonStyles from '../../commonUtils/commonStyles';

import Main from '../../main/Main';

const theme = createTheme({
  palette: {
    secondary: {
      main: commonStyles.color.blue,
    },
    primary: {
      main: commonStyles.color.firmGrey,
    },
    action: {
      disabledBackground: commonStyles.color.blueDisabled,
      disabled: commonStyles.color.white,
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
});

export default function VenueLoginScreen() {
  const setGlobalLoading = (val) => {
    Main.APP_PARAMS.setGlobalState((prevState) => ({
      ...prevState,
      globalLoading: val,
    }));
  };

  const [venuesState, setVenuesState] = useState([]);

  const navigate = useNavigate();

  const [submitButtonState, setSubmitButtonState] = useState(true);
  const [state, setState] = useState({
    host: '',
    hostId: 0,
    hostError: false,
    password: '',
    passwordError: false,
  });

  const { getRequest: loadInitialData } = useServerRequest(setGlobalLoading);
  const { getRequest: uploadData } = useServerRequest(setGlobalLoading);

  useEffect(() => {
    //console.log('Load initial data');
    loadInitialData(API_ENDPOINTS_LIST.getVenuesInitialData, '', 'GET').then((res) => {
      //console.log('loadTableData result: ' + JSON.stringify(res));
      //console.log('loadTableData result: ' + JSON.stringify(res.clientsStatuses));

      if (res && !res.code) {
        //console.log('loadInitialDataHandler: ' + JSON.stringify(res.venues));
        //setMetaDataState(res);
        setVenuesState(res.venues);
      } else {
        let errorText = '';

        if (res.status === 401) {
          errorText = "You don't have access to server";
        }

        if (res.status === 403) {
          errorText = 'Forbidden';
        }

        Main.APP_PARAMS.setGlobalState((prevState) => ({
          ...prevState,
          toastOpen: true,
          toastMessage: `Error! ${errorText}`,
          toastStyle: 'error',
        }));
      }
    });
  }, []);

  useEffect(() => {
    //call function when something change in state
    //check if all mandatory fields is not empty!
    let allFieldsFilled = false;

    //console.log('[dynamicComponentsState: ' + JSON.stringify(dynamicComponentsState));
    let errorsObj = {
      hostError: state.host === '' ? true : false,
      passwordError: state.password === '' ? true : false,
    };

    //console.log('A: ' + JSON.stringify(Object.values(errorsObj)));

    if (
      Object.values(errorsObj).every((item) => {
        return item === false;
      })
    ) {
      allFieldsFilled = true;
    } else {
      allFieldsFilled = false;
    }
    //console.log('allFieldsFilled: ' + allFieldsFilled);

    setSubmitButtonState(!allFieldsFilled);
  }, [state]); //dependency added

  const updateText = (name, val) => {
    setState((prevState) => ({
      ...prevState,
      [name]: val,
    }));
  };

  const handleSubmitButton = () => {
    const dataForServer = {
      venueId: state.hostId,
      password: state.password,
    };
    //console.log('data for server: ');
    //console.log(JSON.stringify(dataForServer));

    uploadData(API_ENDPOINTS_LIST.venueLogin, JSON.stringify(dataForServer)).then((res) => {
      //console.log('res: ' + JSON.stringify(res));
      if (res && res.status === 200) {
        Main.APP_PARAMS.userRole = 'loggedVenue';
        Main.APP_PARAMS.setGlobalState((prevState) => ({
          ...prevState,
          toastOpen: true,
          toastMessage: `You are successfully logged in`,
          toastStyle: 'success',
          authToken: res.accessToken,
        }));
        localStorage.setItem('token', JSON.stringify(res.accessToken));
        localStorage.setItem('loggedVenueData', JSON.stringify(res.data));
        localStorage.setItem('loggedVenueAvailableTypes', JSON.stringify(res.types));
        navigate(ROUTES_LIST.welcome);
      } else {
        Main.APP_PARAMS.setGlobalState((prevState) => ({
          ...prevState,
          toastOpen: true,
          toastMessage: `Error! Reason: ` + res.message,
          toastStyle: 'error',
        }));
      }
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <HeaderComponent screenNumber={7} />
      <Paper style={getStyles().mainPaper} elevation={0}>
        <Grid container direction="row" style={getStyles().topTextGrid}>
          <Grid item xl={3} lg={3} md={3} sm={12} xs={12} style={getStyles().topTextGridColumn1}>
            <Grid item>
              <Typography noWrap style={commonStyles.text.robotoBold30}>
                Toxon App
              </Typography>
            </Grid>
            <Grid container direction="row" alignItems="center">
              <Grid item>
                <CardMedia component="img" image={archeryImage} style={getStyles().archeryImage} />
              </Grid>
              <Grid item>
                <Typography>Archery. Connected.</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xl={9} lg={9} md={9} sm={12} xs={12} style={getStyles().topTextGridColumn2}>
            <CardMedia component="img" image={headImage} style={getStyles().headImage} />
            <Grid container>
              <Grid item>
                <CardMedia component="img" image={logoImage} style={getStyles().logoImage} />
              </Grid>
            </Grid>
            <Grid item xs={12} align="center">
              <Typography
                style={{
                  ...commonStyles.text.robotoRegular24,
                  ...{ marginTop: '70px' },
                }}
              >
                Login to Venue Personal Area
              </Typography>
              <Typography style={{ ...commonStyles.text.robotoRegular18Light }}>
                Venue Profile is a place to manage Venue's data
              </Typography>
            </Grid>
            <Grid item xs={12} align="center" style={{ ...getStyles().textHeader }}>
              <Grid container justifyContent="center">
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={8}
                  lg={8}
                  xl={8}
                  style={{
                    width: '100%',
                    justifyContent: 'flex-start',
                    textAlign: 'start',
                  }}
                >
                  <Typography style={{ ...commonStyles.text.robotoRegular20bold }}>
                    Venue <span style={getStyles().redText}>*</span>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} align="center">
              <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                <Autocomplete
                  fullWidth
                  id="host-select"
                  options={venuesState}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => <TextField {...params} label="Who are you?" variant="outlined" />}
                  onChange={(event, value) => {
                    updateText('host', value ? value.name : '');
                    setState((prevState) => ({
                      ...prevState,
                      hostId: value ? value.id : 0,
                    }));
                  }}
                />
              </Grid>
            </Grid>

            <Grid item xs={12} align="center" style={{ ...getStyles().textHeader }}>
              <Grid container justifyContent="center">
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={8}
                  lg={8}
                  xl={8}
                  style={{
                    width: '100%',
                    justifyContent: 'flex-start',
                    textAlign: 'start',
                  }}
                >
                  <Typography style={{ ...commonStyles.text.robotoRegular20bold }}>
                    Password <span style={getStyles().redText}>*</span>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} align="center">
              <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                <TextField
                  fullWidth
                  label="Your access code"
                  variant="outlined"
                  value={state.password}
                  error={state.passwordError}
                  onChange={(e) => {
                    updateText('password', e.target.value);
                  }}
                />
              </Grid>
            </Grid>

            <Grid item xs={12} align="center" style={{ ...getStyles().textHeader }}>
              <Grid container justifyContent="center">
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={8}
                  lg={8}
                  xl={8}
                  style={{
                    width: '100%',
                    justifyContent: 'flex-start',
                    textAlign: 'start',
                  }}
                >
                  <Button
                    variant="contained"
                    disabled={submitButtonState}
                    color="secondary"
                    style={{ color: commonStyles.color.white }}
                    onClick={() => {
                      handleSubmitButton();
                    }}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} align="center" style={{ ...getStyles().textHeader }}>
              <Grid container justifyContent="center">
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={8}
                  lg={8}
                  xl={8}
                  style={{
                    width: '100%',
                    justifyContent: 'flex-start',
                    textAlign: 'start',
                  }}
                >
                  <Typography style={{ ...commonStyles.text.robotoRegular16 }}>
                    - all fields marked with <span style={getStyles().redText}>*</span> are mandatory
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </ThemeProvider>
  );
}

const getStyles = () => {
  const styles = {
    mainPaper: { marginTop: '100px' },
    archeryImage: {
      maxWidth: '40px',
    },
    topTextGrid: {
      marginTop: '70px',
      minHeight: '100px',
      maxWidth: '100%',
      //background: '#FFAA00',
    },
    topTextGridColumn1: {
      //background: '#DDFF99',
      padding: '30px',
    },
    topTextGridColumn2: {
      //background: '#99FF99',
      padding: '30px',
      userSelect: 'none',
    },
    headImage: {
      maxHeight: '250px',
    },
    logoImage: {
      position: 'absolute',
      maxWidth: '100px',
      marginTop: '-50px',
      marginLeft: '30px',
    },
    venueImage: {
      //maxHeight: '250px',
      maxWidth: '250px',
      //paddingTop: '30px',
      paddingBottom: '30px',
    },
    textHeader: { marginTop: '30px', marginBottom: '30px' },
    redText: { color: '#FF0000' },
  };
  return styles;
};
