import React from 'react';
import { Snackbar } from '@mui/material';
import Alert from '@mui/material/Alert';

function ToastComponent(props) {
  const { open, close, duration, styles, message } = props;

  return (
    <Snackbar
      open={open}
      autoHideDuration={duration}
      onClose={close}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <Alert onClose={close} severity={styles}>
        {message}
      </Alert>
    </Snackbar>
  );
}

export default ToastComponent;
