import React from 'react';

import { ThemeProvider, Grid, createTheme, Paper, CardMedia, Typography } from '@mui/material';

import { ROUTES_LIST } from '../../commonUtils/constants';

import HeaderComponent from '../Components/HeaderComponent/HeaderComponent';

import archeryImage from '../../assets/archery-icon.png';
import commonStyles from '../../commonUtils/commonStyles';

const theme = createTheme({
  palette: {
    secondary: {
      main: commonStyles.color.authError,
    },
    primary: {
      main: commonStyles.color.firmGrey,
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
});

//"https://www.toxonapp.com/Toxon-Contact-Us-f6fc038f474946bd9b062ec2bf956333"

export default function PrivacyPolicyScreen() {
  return (
    <ThemeProvider theme={theme}>
      <HeaderComponent screenNumber={1} />
      <Paper style={getStyles().mainPaper}>
        <Grid container direction="row" style={getStyles().topTextGrid}>
          <Grid item xl={3} lg={3} md={3} sm={12} xs={12} style={getStyles().topTextGridColumn1}>
            <Grid item>
              <Typography noWrap style={commonStyles.text.robotoBold30}>
                Toxon App
              </Typography>
            </Grid>
            <Grid container direction="row" alignItems="center">
              <Grid item>
                <CardMedia component="img" image={archeryImage} style={getStyles().archeryImage} />
              </Grid>
              <Grid item>
                <Typography>Archery. Connected.</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xl={9} lg={9} md={9} sm={12} xs={12} style={getStyles().topTextGridColumn2}>
            <Typography style={commonStyles.text.robotoRegular24}>Toxon Privacy Policy</Typography>
            <Typography style={{ ...commonStyles.text.robotoRegular20bold, ...getStyles().textHeader }}>
              1. Scope
            </Typography>
            <Typography style={getStyles().textBlock}>
              We care about your online privacy. This Privacy Policy (the “Policy”) describes our practices with respect
              to collection, use, disclosure and protection of your information when you use Toxon mobile application
              (the “App”) and/or <span style={getStyles().redText}>toxonapp.com.</span> (the “Site”). The App and the
              Site are referred to herein collectively as the “Platform.”.  Note that this Policy is only valid on our
              Platform, not any third party networks, even if they are referenced on our Platform. It is your
              responsibility to familiarize yourself and comply with any relevant third party networks.
              <br />
              <br />
              If you visit our Platform, that signifies your legal acceptance of the Policy. You must exit the Platform
              if you do not agree with any provision(s) of this Privacy Policy. We reserve the right to change this
              Policy at any time at our sole discretion. The effective date of the last update is at the top of this
              page, so visit it occasionally to see if there are any changes.
            </Typography>
            <Typography style={{ ...commonStyles.text.robotoRegular20bold, ...getStyles().textHeader }}>
              2. Collection of User Data
            </Typography>
            <Typography style={getStyles().textBlock}>
              Here are the types of information regarding the Platform users we may collect:
              <br />
              <br />
              <span style={{ fontStyle: 'italic' }}>a) Personal Info</span>: name, email address, phone number,
              geolocation (of users who access the Platform via mobile phones).
              <br />
              <br />
              <span style={{ fontStyle: 'italic' }}>b) Payment Info</span>: we do not receive your credit or debit card
              information. Third party payment processor handles payments, not us.
              <br />
              <br />
              <span style={{ fontStyle: 'italic' }}>c) Communications</span>: if you contact us for any reason, we will
              receive whatever information you voluntarily provide (e.g., your feedback and reviews).
              <br />
              <br />
              <span style={{ fontStyle: 'italic' }}>d) Your Devices</span>:  device identifiers, phone manufacturer and
              carrier, browser, IP address, operating system version, mobile advertising identifiers, application
              installations.
              <br />
              <br />
              <span style={{ fontStyle: 'italic' }}>e) Platform Interaction</span>: we see what content our Platform
              users access, when and how they interact with the Platform content/pages.
            </Typography>
            <Typography style={{ ...commonStyles.text.robotoRegular20bold, ...getStyles().textHeader }}>
              3. Use of Data
            </Typography>
            <Typography style={getStyles().textBlock}>
              We use the collected data for the following purposes:
              <br />
              <br />
              a) To provide the services and/or information that you have asked for.
              <br />
              <br />
              b) To communicate with you regarding changes to your account or our Platform.
              <br />
              <br />
              c) To send our newsletter but you can unsubscribe.
              <br />
              <br />
              d) To enforce our rights, carry out our obligations, prevent fraud, respond to government requests,
              facilitate disputes between users.
              <br />
              <br />
              e) To accomplish any other purpose for which the information was provided.
            </Typography>
            <Typography style={{ ...commonStyles.text.robotoRegular20bold, ...getStyles().textHeader }}>
              4. Disclosure of Data
            </Typography>
            <Typography style={getStyles().textBlock}>
              In addition to sharing your data as described above, we may disclose the collected personal information as
              follows:
              <br />
              <br />
              a) In case there is a sale, merger or other transfer in the ownership of our Platform, the successor will
              receive personal information about our Platform users along with the assets transferred.
              <br />
              <br />
              b) To our contractors, business partners, third party service providers and other entities or individuals
              who provide support for our Platform. For example, integration and API partners.
              <br />
              <br />
              c) Aggregated, anonymized information that does not identify any particular user can be disclosed without
              restriction.
              <br />
              <br />
              d) To respond to a court order, regulatory request or legal process.
              <br />
              <br />
              e) If we decide that disclosure is appropriate to protect the property, safety, rights of the Platform,
              its users or the public.
            </Typography>
            <Typography style={{ ...commonStyles.text.robotoRegular20bold, ...getStyles().textHeader }}>
              5. Cookie Policy
            </Typography>
            <Typography style={getStyles().textBlock}>
              Cookies are small bits of text data placed on your device when you visit sites. Cookies record data about
              your activity, such as which pages you view and what you click on. Cookies assist our Platform to
              recognize your device when you return. For example, cookies can help us to remember your preferences,
              username, analyze the performance of our Platform and recommend content that may be most relevant to your
              interests.
              <br />
              <br />
              Here are the reasons we may use cookies:
              <br />
              <br />
              <span style={{ fontStyle: 'italic' }}>a) Analytics</span>. This type of cookies shows us which pages users
              view, which links are popular, etc. These cookies only provide anonymized information that does not
              identify anybody personally. This information is then bundled with the similar information from the other
              users, so that we can analyze the general usage patterns.
              <br />
              <br />
              <span style={{ fontStyle: 'italic' }}>b) Essential cookies</span>. These are necessary to provide the
              services that you have asked for. Without these essential cookies, our Platform would not be able to
              operate. They are necessary to enable users to navigate through the Platform and use its main features.
              E.g., essential cookies identify registered users so that they can access member-only areas of the site.
              Essential cookies keep users logged in. If a subscriber disables essential cookies, that subscriber won’t
              be able to get to all of the content that a subscription entitles them to. These cookies don’t collect
              information that could be utilized for the purposes of marketing or figuring out what places on the
              internet you have visited.
              <br />
              <br />
              <span style={{ fontStyle: 'italic' }}>c) To show relevant advertising</span>. If we allow ads on our
              Platform, they can contain cookies in order to provide ads most relevant to your interests. They can also
              help limit the number of times you see a particular ad and to assess the effectiveness of the advertising
              campaign.
              <br />
              <br />
              <span style={{ fontStyle: 'italic' }}>d) To improve your browsing experience</span>. This type of cookies
              enables the site to remember users’ preferences and settings, such as geographic region or language. They
              can also be used to restrict the number of times an ad is shown, to remember which forms you have already
              filled in, so that you don’t have to do it again.
              <br />
              <br />
              <span style={{ fontStyle: 'italic' }}>e) To implement tracking technology on our Platform</span>. This
              tracking does not use your personal information; it uses deidentified data (i.e., data that cannot be tied
              specifically to you). We will not combine this data with your other personal information without your
              prior express permission.
              <br />
              <br />
              <span style={{ fontStyle: 'italic' }}>f) To implement flash cookies</span>. Those are local stored objects
              that collect and store information about your preferences and navigation on our Platform. Flash cookies
              are not managed by the same browser settings as are used for browser cookies.
              <br />
              <br />
              <span style={{ fontStyle: 'italic' }}>g) To implement web beacons</span>. Pages of our the Platform and
              our emails may contain small electronic files known as web beacons (also referred to as clear gifs. pixel
              tags and single-pixel gifs) that permit us, for example, to count users who have visited those pages or
              opened an email and for other related statistics (for example, recording the popularity of certain content
              and verifying system integrity).
              <br />
              <br />
              <span style={{ fontStyle: 'italic' }}>h) To implement pixel tracking</span>. It is a process that involves
              the use of invisible pixel tags that consist of a few lines of computer code. Pixel tracking measures the
              effectiveness of advertisements and compiles aggregate and specific usage statistics. For example, if you
              visit our Platform from an advertisement on another platform, the pixel tag will allow the advertiser to
              track that its advertisement brought you to our Platform. If you visit our Platform, and we link you to
              another website, we may also be able to determine that you were sent to and/or transacted with a
              third-party website. This data is collected for use in our marketing and research.
              <br />
              <br />
              There is a way to turn off cookies by going to your browser’s Help or Settings menu. However, keep in mind
              that disabling cookies may limit your use of the Platform and/or delay or affect the way in which it
              operates.
            </Typography>
            <Typography style={{ ...commonStyles.text.robotoRegular20bold, ...getStyles().textHeader }}>
              6. Data Security
            </Typography>
            <Typography style={getStyles().textBlock}>
              Only our administrators are allowed to access our Platform’s password-protected server where your personal
              information is stored. We utilize SSL. However, any transmission of information over the Internet has its
              inherent risks, so we cannot guarantee the absolute security of your personal information. Transmit
              personal information over the Internet at your own risk. We shall not be liable for circumvention of
              security measures or privacy settings on the Platform. It is your responsibility to keep your login
              credentials, if any, confidential.
            </Typography>
            <Typography style={{ ...commonStyles.text.robotoRegular20bold, ...getStyles().textHeader }}>
              7. Children’s Privacy
            </Typography>
            <Typography style={getStyles().textBlock}>
              We do not knowingly collect any personal information about children under the age of 16. Our Platform is
              not directed to children under the age of 16. If we become aware that a child under 16 has provided any
              personal info, it will be erased from our database as soon as reasonably possible, except when we need to
              keep that information for legal purposes or to notify a parent or guardian. However, portions of this data
              may remain in back-up archives or web logs even after we erase it from our databases. If a parent or
              guardian believes that a child has sent us personal information, send us an e-mail.
            </Typography>
            <Typography style={{ ...commonStyles.text.robotoRegular20bold, ...getStyles().textHeader }}>
              8. Users’ Rights, CCPA
            </Typography>
            <Typography style={getStyles().textBlock}>
              a) We will not share your personal information with third parties for their direct marketing purposes to
              the extent it is forbidden by law. If our practices change, we will do so in accordance with applicable
              laws and will notify you in advance. California law requires that operators of online services disclose
              how they respond to a Do Not Track signal. Some browsers have incorporated “Do Not Track” features. Most
              of these features, when turned on, send a signal or preference to the online service that a user visits,
              indicating that the user does not wish to be tracked. At this time we do not respond to Do Not Track
              signal.
              <br />
              <br />
              b) You can request disclosure of your information collected by us by writing to the email at the end of
              this Policy. We will then provide the requested information, its sources and purposes of use, in a
              portable and easily accessible format within 45 days of the request.
              <br />
              <br />
              c) You have the right to request deletion of your personal information from our systems by submitting a
              request to the email at the end of this Policy.
              <br />
              <br />
              d) You have the right to nondiscrimination for exercising your rights. That means you cannot be denied
              goods or services, charged different prices, or provided different quality of goods/services for asserting
              your legal rights.
            </Typography>
            <Typography style={{ ...commonStyles.text.robotoRegular20bold, ...getStyles().textHeader }}>
              9. International Transfer
            </Typography>
            <Typography style={getStyles().textBlock}>
              We process your personal information in the United States. This is where it will be transferred to in case
              you are located somewhere else. By submitting any personal information to us, you agree to its transfer to
              and processing in the United States.
            </Typography>
            <Typography style={{ ...commonStyles.text.robotoRegular20bold, ...getStyles().textHeader }}>
              10. EU Users’ Rights
            </Typography>
            <Typography style={getStyles().textBlock}>
              This section of our Privacy Policy applies to the users of our Platform in the EU. We would like to inform
              you about your GDPR rights and how we safeguard them.
              <br />
              <br />
              <span style={{ fontStyle: 'italic' }}>
                a) Your GDPR rights to be informed, to access, rectify, erase or restrict the processing of your
                personal information
              </span>
              . You have the right to obtain free information about what personal data we have obtained about you, where
              it is stored, for how long, for what purposes it is used, to whom it was disclosed. You have the right to
              have us, without undue delay, rectify of inaccurate personal data concerning you. That means you can
              request we change your personal data in our records, or have your incomplete personal data completed. You
              have the “right to be forgotten,” i.e. to have us delete your personal information, without undue delay,
              if the data is no longer necessary in relation to the purposes for which it was collected. However, GDPR
              gives us the right to refuse erasure if we can demonstrate compelling legitimate grounds for keeping your
              information.
              <br />
              <br />
              <span style={{ fontStyle: 'italic' }}>b) GDPR gives you the right to restrict processing</span> if any of
              the following applies:i. If you contest the accuracy of your personal data, we will restrict processing it
              for a period enabling us to verify its accuracy.ii. The processing is unlawful and you oppose its erasure
              and request instead the restriction of its use.iii. We no longer need your personal data for the purposes
              of the processing, but you require us to restrict processing for the establishment, exercise or defense of
              legal claims.iv. You have objected to processing pursuant to Article 21(1) of the GDPR pending the
              verification whether our legitimate grounds override yours.
              <br />
              <br />
              <span style={{ fontStyle: 'italic' }}>c) Right to data portability</span>. Upon request, we will provide
              you your personal data in our possession, in a structured, commonly used and machine-readable format. You
              have the right to transmit that data to another controller if doing so does not adversely affect the
              rights and freedoms of others.
              <br />
              <br />
              <span style={{ fontStyle: 'italic' }}>d) Right to object</span>. You can object, on grounds relating your
              particular situation, at any time, to processing of your personal information, if based on point (e) or
              (f) of Article 6(1) of the GDPR.  We will then have to stop processing, unless we can demonstrate
              compelling legitimate grounds for the processing. If you object to the processing for direct marketing
              purposes, we will have to stop processing for these purposes.
              <br />
              <br />
              <span style={{ fontStyle: 'italic' }}>e) Right to withdraw consent</span>. GDPR grants you the right to
              withdraw your earlier given consent, if any, to processing of your personal data at any time.
              <br />
              <br />
              <span style={{ fontStyle: 'italic' }}>f) Rights related to automated decision making</span>. As a
              responsible business, we do not rely on any automated decision making, such as profiling.
            </Typography>
            <Typography style={{ ...commonStyles.text.robotoRegular20bold, ...getStyles().textHeader }}>
              11. Accessing and Correcting Your Personal Information
            </Typography>
            <Typography style={{ ...getStyles().textBlock, ...{ marginBottom: '50px' } }}>
              You can view and edit some of your account information yourself after logging in. If you terminate
              account, we may retain some information for as long as necessary to evaluate Platform usage, troubleshoot
              issues, resolve disputes and collect any fees owed. If you have any questions or wish to ask to access,
              modify or delete any of your personal data on our Platform, please send us a message using{' '}
              <a href={ROUTES_LIST.contactUs}>this form</a> Note that we can deny your request if we think it would
              violate any law or cause the information to be incorrect.
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </ThemeProvider>
  );
}

const getStyles = () => {
  const styles = {
    mainPaper: { marginTop: '100px' },
    archeryImage: {
      maxWidth: '40px',
    },
    topTextGrid: {
      marginTop: '70px',
      minHeight: '100px',
      maxWidth: '100%',
      //background: '#FFAA00',
    },
    topTextGridColumn1: {
      //background: '#DDFF99',
      padding: '30px',
    },
    topTextGridColumn2: {
      //background: '#99FF99',
      padding: '30px',
      userSelect: 'none',
    },
    textHeader: { marginTop: '30px', marginBottom: '30px' },
    textBlock: { borderBottom: '1px solid', borderBottomColor: 'rgba(0, 0, 0, 0.2)', paddingBottom: '15px' },
    redText: { color: '#FF0000' },
  };
  return styles;
};
