const commonStyles = {
  color: {
    backgroundDark: '#333333',
    backgroundWhite: '#FFFFFF',
    white: '#FFFFFF',
    black: '#000000',
    blue: '#2C5282',
    blueEvent: '#1976D2',
    blueShoot: '#43A047',
    blueDisabled: 'rgba(44, 82, 130, 0.5)',
    firmGrey: '#4A6572',
    firmRed: '#842625',
    tableButtonBlack: '#555555',
    tableButtonRed: '#D95551',
    tabInactive: '#787878',
    authError: '#E4333F',
    helperText: '#787878',
    greyLight: '#E0E0E0',
  },
  text: {
    robotoBold40: {
      fontFamily: '"Roboto", sans-serif',
      fontStyle: 'normal',
      fontSize: '40px',
      fontWeight: '900',
    },
    robotoBold30: {
      fontFamily: '"Roboto", sans-serif',
      fontStyle: 'normal',
      fontSize: '29px',
      fontWeight: '900',
    },
    robotoRegular24: {
      fontFamily: '"Roboto", sans-serif',
      fontStyle: 'normal',
      fontSize: '23px',
      fontWeight: '550',
    },
    robotoRegular22boldItalic: {
      fontFamily: '"Roboto", sans-serif',
      fontStyle: 'italic',
      fontSize: '21px',
      fontWeight: '900',
    },
    robotoRegular20bold: {
      fontFamily: '"Roboto", sans-serif',
      fontStyle: 'normal',
      fontSize: '19px',
      fontWeight: '900',
    },
    robotoRegular18: {
      fontSize: '17px',
      fontFamily: '"Roboto", sans-serif',
      fontStyle: 'normal',
      fontWeight: '550',
    },
    robotoRegular18Light: {
      fontSize: '17px',
      fontFamily: '"Roboto", sans-serif',
      fontStyle: 'normal',
      //fontWeight: 550,
    },
    robotoRegular16: {
      fontSize: '15px',
      fontFamily: '"Roboto", sans-serif',
      fontStyle: 'normal',
      fontWeight: 550,
    },
    robotoRegular16Bold: {
      fontSize: '15px',
      fontFamily: '"Roboto", sans-serif',
      fontStyle: 'normal',
      fontWeight: 900,
    },
    robotoRegular15: {
      fontSize: '14px',
      fontFamily: '"Roboto", sans-serif',
      fontStyle: 'normal',
      fontWeight: 550,
    },
    robotoRegular15Bold: {
      fontSize: '14px',
      fontFamily: '"Roboto", sans-serif',
      fontStyle: 'normal',
      fontWeight: 900,
    },
  },
};

export default commonStyles;
