export const ROUTES_LIST = {
  home: '/home',
  contactUs: '/contact-us',
  registerVenue: '/register-venue',
  addEvent: '/add-event',
  privacyPolicy: '/privacy-policy',
  eula: '/eula',
  payment: '/payment',
  venueLogin: '/venue-login',
  venuePersonalData: '/venue-personal-data',
  welcome: '/welcome',
  venuePushNotifications: '/venue-push-notifications',
  venueEvents: '/venue-events',
  venueEditEvent: '/venue-edit-event',
};

export const API_ENDPOINTS_LIST = {
  //hosts/events
  getVenuesInitialData: '/approved-venues',
  addHost: '/venues/add',
  addEvent: '/listings/add',
  sendContactUsMessage: '/contact-us',
  getSignedFileUrl: '/signed-file-url',
  //upload
  uploadImageGetPreview: '/upload-image',
  //payment
  createVenuePayment: '/payments/venue',
  //venue personal page
  venueLogin: '/venues/login',
  venueSendPushNotification: '/venues/push-notifications/publish',
  getPushNotificationsHistory: '/venues/push-notifications/history',
  venueUpdate: '/venues/update',
  getEventsList: '/listings/list',
  updateEvent: '/listings/update',
  deleteEvent: '/listings/delete',
  //venue personal page - Admin functions
  changePushNotificationsPermission: '/venues/push-notifications/set-permission',
};
