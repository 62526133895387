import React, { useState } from 'react';

import { Paper, Grid, CardMedia } from '@mui/material';

import logo from '../../assets/ToxonLogo.png';
import maintenance from '../../assets/maintenance.jpg';
import commonStyles from '../../commonUtils/commonStyles';

function MaintenanceScreen() {
  const [imageLoaded, setImageLoaded] = useState({
    image1: false,
    image2: false,
  });
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: '100vh', background: commonStyles.color.backgroundDark }}
    >
      <Grid item xs={10}>
        <Paper elevation={3} hidden={!imageLoaded.image1 && !imageLoaded.image2 ? true : false}>
          <Grid container>
            <Grid item style={getStyles().header}>
              <CardMedia
                component="img"
                image={logo}
                onLoad={() => {
                  //console.log('Image1 loaded');
                  setImageLoaded((prevState) => ({
                    ...prevState,
                    image1: true,
                  }));
                }}
                style={getStyles().logoImage}
              />
              <CardMedia
                component="img"
                image={maintenance}
                onLoad={() => {
                  //console.log('Image2 loaded');
                  setImageLoaded((prevState) => ({
                    ...prevState,
                    image2: true,
                  }));
                }}
                style={getStyles().maintenanceImage}
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}

const getStyles = () => {
  const styles = {
    logoImage: {
      maxWidth: '100px',
    },
    maintenanceImage: {
      maxWidth: '300px',
      marginTop: '30px',
      marginBottom: '30px',
    },
    header: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      padding: '25px',
    },
  };
  return styles;
};

export default MaintenanceScreen;
