import React, { useState, useEffect } from 'react';

import {
  ThemeProvider,
  Grid,
  createTheme,
  Paper,
  CardMedia,
  Typography,
  TextField,
  Button,
  Switch,
  Stack,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';

import useUploadRequest from '../../hooks/uploadRequest';
import useServerRequest from '../../hooks/serverRequest';
import { API_ENDPOINTS_LIST } from '../../commonUtils/constants';
//import { ROUTES_LIST } from '../../commonUtils/constants';

import HeaderComponent from '../Components/HeaderComponent/HeaderComponent';
import DateAndTimeComponent from './DateAndTimeComponent/DateAndTimeComponent';

//import noImage from '../../assets/no_image_placeholder.png';
import logoImage from '../../assets/ToxonLogo.png';
import archeryImage from '../../assets/archery-icon.png';
import headImage from '../../assets/add_event_head.jpg';
import commonStyles from '../../commonUtils/commonStyles';

import { validateLatitude, validateLongitude, convertDateTimeToUTC } from '../../commonUtils/commonUtils';

import Main from '../../main/Main';

const theme = createTheme({
  palette: {
    secondary: {
      main: commonStyles.color.blue,
    },
    primary: {
      main: commonStyles.color.firmGrey,
    },
    action: {
      disabledBackground: commonStyles.color.blueDisabled,
      disabled: commonStyles.color.white,
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
});

const maxDates = 7;

const fileRef = React.createRef();

export default function AddEventScreen() {
  const setGlobalLoading = (val) => {
    Main.APP_PARAMS.setGlobalState((prevState) => ({
      ...prevState,
      globalLoading: val,
    }));
  };

  const [venuesState, setVenuesState] = useState([]);
  const [shootTypesState, setShootTypesState] = useState([]);

  const [submitButtonState, setSubmitButtonState] = useState(true);
  const [state, setState] = useState({
    host: '',
    hostId: 0,
    hostError: false,
    name: '',
    nameError: false,
    type: '',
    typeError: false,
    shootType: [],
    shootTypeError: false,
    image: 'https://toxon-bucket.s3.us-east-2.amazonaws.com/images/cb17e082-cfb5-4167-bbcc-03aecaa97adc.png',
    imageError: false,
    local_event: false,
    latitude: '',
    latitudeError: false,
    longitude: '',
    longitudeError: false,
    description: '',
    descriptionError: false,
    datesError: false,
    password: '',
    passwordError: false,
  });

  const [dynamicComponentsState, setDynamicComponentsState] = useState({
    datesArr: [
      {
        start: '',
        end: '',
      },
    ],
  });

  const { getRequest: loadInitialData } = useServerRequest(setGlobalLoading);
  //const { uploadRequest: uploadImage } = useUploadRequest(setGlobalLoading);
  const { getRequest: uploadData } = useServerRequest(setGlobalLoading);

  const { getRequest: getSignedImageUrl } = useServerRequest(setGlobalLoading);

  useEffect(() => {
    //console.log('Load initial data');
    loadInitialData(API_ENDPOINTS_LIST.getVenuesInitialData, '', 'GET').then((res) => {
      //console.log('loadTableData result: ' + JSON.stringify(res));
      //console.log('loadTableData result: ' + JSON.stringify(res.clientsStatuses));

      if (res && !res.code) {
        //console.log('loadInitialDataHandler: ' + JSON.stringify(res));
        //setMetaDataState(res);
        setVenuesState(res.venues);
        setShootTypesState(res.shootTypes);
      } else {
        let errorText = '';

        if (res.status === 401) {
          errorText = "You don't have access to server";
        }

        if (res.status === 403) {
          errorText = 'Forbidden';
        }

        Main.APP_PARAMS.setGlobalState((prevState) => ({
          ...prevState,
          toastOpen: true,
          toastMessage: `Error! ${errorText}`,
          toastStyle: 'error',
        }));
      }
    });
  }, []);

  useEffect(() => {
    //call function when something change in state
    //check if all mandatory fields is not empty!
    let allFieldsFilled = false;
    let datesDataArr = dynamicComponentsState.datesArr;
    let datesError = false;
    datesDataArr.forEach((obj) => {
      if (obj.start === '' || obj.end === '') datesError = true;
    });
    //console.log('[dynamicComponentsState: ' + JSON.stringify(dynamicComponentsState));
    let errorsObj = {
      hostError: state.host === '' ? true : false,
      nameError: state.name === '' ? true : false,
      // imageError: state.image === "" ? true : false,
      typeError: state.type === '' ? true : false,
      shootTypeError: state.shootType.length === 0 ? true : false,
      latitudeError: state.latitude === '' ? true : false,
      longitudeError: state.longitude === '' ? true : false,
      descriptionError: state.description === '' ? true : false,
      datesError: datesError,
      passwordError: state.password === '' ? true : false,
    };

    //console.log('A: ' + JSON.stringify(Object.values(errorsObj)));

    if (
      Object.values(errorsObj).every((item) => {
        return item === false;
      })
    ) {
      allFieldsFilled = true;
    } else {
      allFieldsFilled = false;
    }
    //console.log('allFieldsFilled: ' + allFieldsFilled);

    setSubmitButtonState(!allFieldsFilled);
  }, [state, dynamicComponentsState]); //dependency added

  const addNewDate = () => {
    if (dynamicComponentsState.datesArr.length < maxDates) {
      let datesDataArr = dynamicComponentsState.datesArr;
      //let datesCount = datesDataArr.length;
      datesDataArr.push({
        start: '',
        end: '',
      });
      setDynamicComponentsState((prevState) => ({
        ...prevState,
        datesArr: datesDataArr,
      }));
    }
  };

  const deleteDate = () => {
    let datesDataArr = dynamicComponentsState.datesArr;
    if (datesDataArr.length > 1) {
      datesDataArr.pop();
    }
    setDynamicComponentsState((prevState) => ({
      ...prevState,
      datesArr: datesDataArr,
    }));
  };

  const setStartDateTime = (index, data) => {
    //console.log('index, data: ' + index + ':' + data);
    //let datesDataArr = dynamicComponentsState.datesArr;

    let datesDataArr = dynamicComponentsState.datesArr;
    datesDataArr[index].start = data;
    //console.log('Set start date: ' + JSON.stringify(datesDataArr));
    setDynamicComponentsState((prevState) => ({
      ...prevState,
      datesArr: datesDataArr,
    }));
  };

  const setEndDateTime = (index, data) => {
    let datesDataArr = dynamicComponentsState.datesArr;
    datesDataArr[index].end = data;
    //console.log('Set end date: ' + JSON.stringify(datesDataArr));
    setDynamicComponentsState((prevState) => ({
      ...prevState,
      datesArr: datesDataArr,
    }));
  };

  const updateText = (name, val) => {
    setState((prevState) => ({
      ...prevState,
      [name]: val,
    }));
  };

  const handleRadioButtonsChange = (val) => {
    setState((prevState) => ({
      ...prevState,
      type: val,
    }));
  };

  const handleSwitchChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
    if (event.target.checked && state.hostId) {
      const selectedHost = venuesState.find((x) => x.id === state.hostId);
      //console.log('switch: ' + JSON.stringify(selectedHost));
      setState((prevState) => ({
        ...prevState,
        latitude: selectedHost.latitude,
        longitude: selectedHost.longitude,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        latitude: '',
        longitude: '',
      }));
    }
  };

  const handleSubmitButton = () => {
    //console.log('state: ' + JSON.stringify(state));
    //check for correct fields
    let errorsObj = {
      imageError: state.image === '' ? true : false,
      latitudeError: state.latitude !== '' ? !validateLatitude(state.latitude) : false,
      longitudeError: state.longitude !== '' ? !validateLongitude(state.longitude) : false,
    };

    if (
      Object.values(errorsObj).every((item) => {
        return item === false;
      })
    ) {
      //everything Ok - send data
      setState((prevState) => ({
        ...prevState,
        imageError: false,
        latitudeError: false,
        longitudeError: false,
      }));

      const datesArr = JSON.parse(JSON.stringify(dynamicComponentsState.datesArr));
      datesArr.forEach((obj) => {
        //console.log('obj.start: ' + JSON.stringify(obj.start) + ' obj.end: ' + JSON.stringify(obj.end));
        obj.start = convertDateTimeToUTC(obj.start);
        obj.end = convertDateTimeToUTC(obj.end);
      });
      //console.log('dates: ' + JSON.stringify(datesArr));
      //console.log('Original date: ' + dynamicComponentsState.datesArr[0].start);
      //console.log('UTC date: ' + JSON.stringify(convertDateTimeToUTC(dynamicComponentsState.datesArr[0].start)));
      const dataForServer = {
        listing: {
          name: state.name,
          host_id: state.hostId,
          type: state.type,
          shootType: state.shootType,
          images: [state.image],
          latitude: Number(state.latitude),
          longitude: Number(state.longitude),
          description: state.description,
          dates: datesArr,
          password: state.password,
        },
      };
      //console.log('data for server: ');
      //console.log(JSON.stringify(dataForServer));

      uploadData(API_ENDPOINTS_LIST.addEvent, JSON.stringify(dataForServer)).then((res) => {
        if (res && res.status === 200) {
          Main.APP_PARAMS.setGlobalState((prevState) => ({
            ...prevState,
            toastOpen: true,
            toastMessage: `Your event/shoot is added to Toxon App! Thank you!`,
            toastStyle: 'success',
          }));
        } else {
          Main.APP_PARAMS.setGlobalState((prevState) => ({
            ...prevState,
            toastOpen: true,
            toastMessage: `Error! Can't add data to server. Please try again or contact us!`,
            toastStyle: 'error',
          }));
        }
      });
    } else {
      Main.APP_PARAMS.setGlobalState((prevState) => ({
        ...prevState,
        toastOpen: true,
        toastMessage: `Some fields is incorrect!`,
        toastStyle: 'error',
      }));
      setState((prevState) => ({
        ...prevState,
        imageError: errorsObj.imageError,
        latitudeError: errorsObj.latitudeError,
        longitudeError: errorsObj.longitudeError,
      }));
    }
  };
  /*
  const handleFileUpload = () => {
    fileRef.current.click();
  };

  const handleImageUpload = async (event) => {
    //console.log('handleImageUpload: ' + fileRef.current.files.length);
    if (fileRef.current.files.length) {
      // start preloader
      const input = document.querySelector('input[type="file"]');
      const [file] = input.files;

      const uploadFileUrl = await getSignedImageUrl(
        API_ENDPOINTS_LIST.getSignedFileUrl + '?format=' + file.type.split('/')[1],
        '',
        'GET'
      );

      const imageUploaded = await uploadImage(uploadFileUrl.fileUrl, file, false);

      if (imageUploaded.status === 200) {
        setState((prevState) => ({
          ...prevState,
          image: uploadFileUrl.fileUrl.split('?')[0],
        }));
      } else {
        Main.APP_PARAMS.setGlobalState((prevState) => ({
          ...prevState,
          toastOpen: true,
          toastMessage: `Upload was not successful!`,
          toastStyle: 'error',
        }));
      }
    }
  };
  */
  return (
    <ThemeProvider theme={theme}>
      <HeaderComponent screenNumber={5} />
      <Paper style={getStyles().mainPaper} elevation={0}>
        <Grid container direction="row" style={getStyles().topTextGrid}>
          <Grid item xl={3} lg={3} md={3} sm={12} xs={12} style={getStyles().topTextGridColumn1}>
            <Grid item>
              <Typography noWrap style={commonStyles.text.robotoBold30}>
                Toxon App
              </Typography>
            </Grid>
            <Grid container direction="row" alignItems="center">
              <Grid item>
                <CardMedia component="img" image={archeryImage} style={getStyles().archeryImage} />
              </Grid>
              <Grid item>
                <Typography>Archery. Connected.</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xl={9} lg={9} md={9} sm={12} xs={12} style={getStyles().topTextGridColumn2}>
            <CardMedia component="img" image={headImage} style={getStyles().headImage} />
            <Grid container>
              <Grid item>
                <CardMedia component="img" image={logoImage} style={getStyles().logoImage} />
              </Grid>
            </Grid>
            <Grid item xs={12} align="center">
              <Typography
                style={{
                  ...commonStyles.text.robotoRegular24,
                  ...{ marginTop: '70px' },
                }}
              >
                Create a Listing
              </Typography>
              <Typography style={{ ...commonStyles.text.robotoRegular18Light }}>
                Add a listing for the Toxon App
              </Typography>
            </Grid>
            <Grid item xs={12} align="center" style={{ ...getStyles().textHeader }}>
              <Grid container justifyContent="center">
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={8}
                  lg={8}
                  xl={8}
                  style={{
                    width: '100%',
                    justifyContent: 'flex-start',
                    textAlign: 'start',
                  }}
                >
                  <Typography style={{ ...commonStyles.text.robotoRegular20bold }}>
                    Host <span style={getStyles().redText}>*</span>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} align="center">
              <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                <Autocomplete
                  fullWidth
                  id="host-select"
                  options={venuesState}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => <TextField {...params} label="Who is hosting?" variant="outlined" />}
                  onChange={(event, value) => {
                    updateText('host', value ? value.name : '');
                    setState((prevState) => ({
                      ...prevState,
                      hostId: value ? value.id : 0,
                    }));
                    if (state.local_event) {
                      setState((prevState) => ({
                        ...prevState,
                        latitude: value.latitude,
                        longitude: value.longitude,
                      }));
                    }
                  }}
                />
              </Grid>
            </Grid>

            <Grid item xs={12} align="center" style={{ ...getStyles().textHeader }}>
              <Grid container justifyContent="center">
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={8}
                  lg={8}
                  xl={8}
                  style={{
                    width: '100%',
                    justifyContent: 'flex-start',
                    textAlign: 'start',
                  }}
                >
                  <Typography style={{ ...commonStyles.text.robotoRegular20bold }}>
                    Name <span style={getStyles().redText}>*</span>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} align="center">
              <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                <TextField
                  fullWidth
                  label="Name of your listing"
                  variant="outlined"
                  value={state.name}
                  error={state.nameError}
                  onChange={(e) => {
                    updateText('name', e.target.value);
                  }}
                />
              </Grid>
            </Grid>

            <Grid item xs={12} align="center" style={{ ...getStyles().textHeader }}>
              <Grid container justifyContent="center">
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={8}
                  lg={8}
                  xl={8}
                  style={{
                    width: '100%',
                    justifyContent: 'flex-start',
                    textAlign: 'start',
                  }}
                >
                  <Typography style={{ ...commonStyles.text.robotoRegular20bold }}>
                    Type <span style={getStyles().redText}>*</span>
                  </Typography>
                  <Typography style={{ ...commonStyles.text.robotoRegular18Light }}>
                    <span style={{ fontWeight: 'bold' }}>
                      <span style={{ fontSize: '1.4rem' }}>&bull;</span>
                    </span>
                    {'   '} Shoots are archery competitions
                    <br />
                  </Typography>
                  <Typography style={{ ...commonStyles.text.robotoRegular18Light }}>
                    <span style={{ fontWeight: 'bold' }}>
                      <span style={{ fontSize: '1.4rem' }}>&bull;</span>
                    </span>
                    {'   '} Events are for anything else
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} align="center">
              <Grid
                item
                xs={12}
                sm={12}
                md={8}
                lg={8}
                xl={8}
                //style={state.typeError === true ? { border: '1px solid', borderColor: 'red' } : null}
              >
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="type"
                    name="radio-buttons-group"
                    onChange={(e) => {
                      handleRadioButtonsChange(e.target.value);
                    }}
                  >
                    <FormControlLabel value="shoot" control={<Radio color="secondary" />} label="Shoot" />
                    <FormControlLabel value="event" control={<Radio color="secondary" />} label="Event" />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>

            <Grid item xs={12} align="center" style={{ ...getStyles().textHeader }}>
              <Grid container justifyContent="center">
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={8}
                  lg={8}
                  xl={8}
                  style={{
                    width: '100%',
                    justifyContent: 'flex-start',
                    textAlign: 'start',
                  }}
                >
                  <Typography style={{ ...commonStyles.text.robotoRegular20bold }}>
                    Shoot/Event Type <span style={getStyles().redText}>*</span>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} align="center">
              <Grid
                item
                xs={12}
                sm={12}
                md={8}
                lg={8}
                xl={8}
                //style={state.typeError === true ? { border: '1px solid', borderColor: 'red' } : null}
              >
                <Autocomplete
                  multiple
                  fullWidth
                  id="shoot-type-select"
                  options={shootTypesState}
                  //getOptionLabel={(option) => option.label}
                  renderInput={(params) => <TextField {...params} label="Select shoot type" variant="outlined" />}
                  onChange={(event, value) => {
                    /*
                    var labels = value.map(function (item) {
                      return item['label'];
                    });
                    */
                    //console.log('shootType ' + JSON.stringify(value));
                    updateText('shootType', value);
                  }}
                />
              </Grid>
            </Grid>
            {/*
            <Grid
              item
              xs={12}
              align="center"
              style={{
                ...getStyles().textHeader,
              }}
            >
              <Grid container justifyContent="center">
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={8}
                  lg={8}
                  xl={8}
                  style={{
                    width: '100%',
                    justifyContent: 'flex-start',
                    textAlign: 'start',
                  }}
                >
                  <Typography style={{ ...commonStyles.text.robotoRegular20bold }}>
                    Image <span style={getStyles().redText}>*</span>
                  </Typography>
                  <Typography style={{ ...commonStyles.text.robotoRegular18Light }}>Event's preview</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} align="center">
              <Grid
                item
                xs={12}
                sm={12}
                md={8}
                lg={8}
                xl={8}
                //style={state.imageError === true ? { border: '1px solid', borderColor: 'red' } : null}
              >
                <img style={getStyles().venueImage} src={state.image === '' ? noImage : state.image} alt="" />
              </Grid>
              <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                <Button
                  variant="contained"
                  color="default"
                  style={{ color: commonStyles.color.black }}
                  onClick={() => {
                    handleFileUpload();
                  }}
                >
                  Select & Upload
                </Button>
                <input type="file" hidden ref={fileRef} onChange={(event) => handleImageUpload(event)} accept="file" />
              </Grid>
            </Grid>
            */}
            <Grid item xs={12} align="center" style={{ ...getStyles().textHeader }}>
              <Grid container justifyContent="center">
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={8}
                  lg={8}
                  xl={8}
                  style={{
                    width: '100%',
                    justifyContent: 'flex-start',
                    textAlign: 'start',
                  }}
                >
                  <Typography style={{ ...commonStyles.text.robotoRegular20bold }}>
                    Latitude/Longitude <span style={getStyles().redText}>*</span>
                  </Typography>
                  <Typography style={{ ...commonStyles.text.robotoRegular18Light }}>
                    We use latitude and longitude to show your event's location on the map.
                  </Typography>
                  <Typography style={{ ...commonStyles.text.robotoRegular18Light }}>
                    Not sure what's your event's lat, lon coordinates? Use this free service:{' '}
                    <a href="https://www.latlong.net/convert-address-to-lat-long.html" target="_blank" rel="noreferrer">
                      www.LatLong.net
                    </a>
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={8}
                  lg={8}
                  xl={8}
                  style={{
                    width: '100%',
                    justifyContent: 'flex-start',
                    textAlign: 'start',
                  }}
                >
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Typography>IS THIS EVENT HOSTED AT YOUR CLUB?</Typography>
                    <Typography>NO</Typography>
                    <Switch checked={state.local_event} onChange={handleSwitchChange} name="local_event" />
                    <Typography>YES</Typography>
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} align="center" style={{ ...{ marginBottom: '50px' } }}>
              <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                <TextField
                  fullWidth
                  disabled={state.local_event}
                  label="Latitude"
                  variant="outlined"
                  value={state.latitude}
                  error={state.latitudeError}
                  onChange={(e) => {
                    updateText('latitude', e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={8} lg={8} xl={8} style={{ marginTop: '30px' }}>
                <TextField
                  fullWidth
                  disabled={state.local_event}
                  label="Longitude"
                  variant="outlined"
                  value={state.longitude}
                  error={state.longitudeError}
                  onChange={(e) => {
                    updateText('longitude', e.target.value);
                  }}
                />
              </Grid>
            </Grid>

            <Grid item xs={12} align="center" style={{ ...getStyles().textHeader }}>
              <Grid container justifyContent="center">
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={8}
                  lg={8}
                  xl={8}
                  style={{
                    width: '100%',
                    justifyContent: 'flex-start',
                    textAlign: 'start',
                  }}
                >
                  <Typography style={{ ...commonStyles.text.robotoRegular20bold }}>
                    Description <span style={getStyles().redText}>*</span>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} align="center">
              <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                <TextField
                  fullWidth
                  multiline
                  minRows={3}
                  label="Write something about your event"
                  variant="outlined"
                  value={state.description}
                  onChange={(e) => {
                    updateText('description', e.target.value);
                  }}
                />
              </Grid>
            </Grid>

            {dynamicComponentsState.datesArr.map((componentData, index) => (
              <DateAndTimeComponent
                key={index}
                index={index}
                startDate={componentData.start === '' ? null : componentData.start}
                endDate={componentData.end === '' ? null : componentData.end}
                setStartDateTime={(i, d) => setStartDateTime(i, d)}
                setEndDateTime={(i, d) => setEndDateTime(i, d)}
              />
            ))}

            <Grid item xs={12} align="center">
              <Grid container justifyContent="center">
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={8}
                  lg={8}
                  xl={8}
                  style={{
                    width: '100%',
                    justifyContent: 'flex-start',
                    textAlign: 'start',
                  }}
                >
                  <Grid container direction="row" justifyContent="space-evenly">
                    <Button
                      variant="contained"
                      disabled={dynamicComponentsState.datesArr.length < maxDates ? false : true}
                      color="primary"
                      style={{
                        color: commonStyles.color.white,
                        marginTop: '30px',
                      }}
                      onClick={() => {
                        addNewDate();
                      }}
                    >
                      Add date
                    </Button>
                    <Button
                      variant="contained"
                      disabled={dynamicComponentsState.datesArr.length > 1 ? false : true}
                      color="primary"
                      style={{
                        color: commonStyles.color.white,
                        marginTop: '30px',
                      }}
                      onClick={() => {
                        deleteDate();
                      }}
                    >
                      Remove date
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} align="center" style={{ ...getStyles().textHeader }}>
              <Grid container justifyContent="center">
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={8}
                  lg={8}
                  xl={8}
                  style={{
                    width: '100%',
                    justifyContent: 'flex-start',
                    textAlign: 'start',
                  }}
                >
                  <Typography style={{ ...commonStyles.text.robotoRegular18Light }}>
                    You can add up to {maxDates} dates!
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} align="center" style={{ ...getStyles().textHeader }}>
              <Grid container justifyContent="center">
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={8}
                  lg={8}
                  xl={8}
                  style={{
                    width: '100%',
                    justifyContent: 'flex-start',
                    textAlign: 'start',
                  }}
                >
                  <Typography style={{ ...commonStyles.text.robotoRegular20bold }}>
                    Password <span style={getStyles().redText}>*</span>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} align="center">
              <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                <TextField
                  fullWidth
                  label="Your access code"
                  variant="outlined"
                  value={state.password}
                  error={state.passwordError}
                  onChange={(e) => {
                    updateText('password', e.target.value);
                  }}
                />
              </Grid>
            </Grid>

            <Grid item xs={12} align="center" style={{ ...getStyles().textHeader }}>
              <Grid container justifyContent="center">
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={8}
                  lg={8}
                  xl={8}
                  style={{
                    width: '100%',
                    justifyContent: 'flex-start',
                    textAlign: 'start',
                  }}
                >
                  <Button
                    variant="contained"
                    disabled={submitButtonState}
                    color="secondary"
                    style={{ color: commonStyles.color.white }}
                    onClick={() => {
                      handleSubmitButton();
                    }}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} align="center" style={{ ...getStyles().textHeader }}>
              <Grid container justifyContent="center">
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={8}
                  lg={8}
                  xl={8}
                  style={{
                    width: '100%',
                    justifyContent: 'flex-start',
                    textAlign: 'start',
                  }}
                >
                  <Typography style={{ ...commonStyles.text.robotoRegular16 }}>
                    - all fields marked with <span style={getStyles().redText}>*</span> are mandatory
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </ThemeProvider>
  );
}

const getStyles = () => {
  const styles = {
    mainPaper: { marginTop: '100px' },
    archeryImage: {
      maxWidth: '40px',
    },
    topTextGrid: {
      marginTop: '70px',
      minHeight: '100px',
      maxWidth: '100%',
      //background: '#FFAA00',
    },
    topTextGridColumn1: {
      //background: '#DDFF99',
      padding: '30px',
    },
    topTextGridColumn2: {
      //background: '#99FF99',
      padding: '30px',
      userSelect: 'none',
    },
    headImage: {
      maxHeight: '250px',
    },
    logoImage: {
      position: 'absolute',
      maxWidth: '100px',
      marginTop: '-50px',
      marginLeft: '30px',
    },
    venueImage: {
      //maxHeight: '250px',
      maxWidth: '250px',
      //paddingTop: '30px',
      paddingBottom: '30px',
    },
    textHeader: { marginTop: '30px', marginBottom: '30px' },
    redText: { color: '#FF0000' },
  };
  return styles;
};
