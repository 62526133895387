import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
// import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import { Paper, Typography, Grid, Tooltip, Divider } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import TableButton from '../../Components/TableButton/TableButton';

import commonStyles from '../../../commonUtils/commonStyles';

//const moment = require('moment');

const columns = [
  { id: 'name', label: 'Name', maxWidth: '100px', align: 'left' },
  {
    id: 'type',
    label: 'Type',
    maxWidth: '100px',
    align: 'left',
    //format: (value) => value.toLocaleString('en-US'),
    componentRender: (props) => {
      let colorType = '';
      if (props.type === 'event') colorType = commonStyles.color.blueEvent;
      if (props.type === 'shoot') colorType = commonStyles.color.blueShoot;
      return (
        <Grid container direction="column">
          <Grid item>
            <Typography
              style={{
                ...commonStyles.text.robotoRegular16Bold,
                color: colorType,
              }}
            >
              {props.type}
            </Typography>
          </Grid>
          {props.shootType && props.shootType.length > 0 ? (
            <Grid item>
              <Typography
                style={{
                  ...commonStyles.text.robotoRegular15,
                  color: commonStyles.color.firmGrey,
                }}
              >
                Shoot types:
              </Typography>
            </Grid>
          ) : null}
          {props.shootType && props.shootType.length > 0
            ? props.shootType.map((item, index) => {
                return (
                  <Grid key={index} item>
                    <Typography
                      style={{
                        ...commonStyles.text.robotoRegular15Bold,
                        //color: colorType,
                      }}
                    >
                      {item}
                    </Typography>
                  </Grid>
                );
              })
            : null}
        </Grid>
      );
    },
  },
  {
    id: 'lat/lon',
    label: 'Latitude/Longitude',
    maxWidth: '100px',
    align: 'left',
    componentRender: (props) => {
      return (
        <Grid container direction="column">
          <Grid item>
            <Typography
              style={{
                ...commonStyles.text.robotoRegular15,
              }}
            >
              Lat: {props.latitude}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              style={{
                ...commonStyles.text.robotoRegular15,
              }}
            >
              Lon: {props.longitude}
            </Typography>
          </Grid>
        </Grid>
      );
    },
  },
  {
    id: 'description',
    label: 'Description',
    maxWidth: '250px',
    align: 'left',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'dates',
    label: 'Dates',
    maxWidth: '100px',
    align: 'left',
    //format: (value) => moment(value).format('YYYY-MM-DD HH:mm'),
    componentRender: (props) => {
      return (
        <Grid container direction="column">
          {props.dates && props.dates.length > 0
            ? props.dates.map((item, index) => {
                //console.log('date item: ' + JSON.stringify(item));
                return (
                  <React.Fragment key={index}>
                    <Grid container direction="row">
                      <Typography
                        style={{
                          ...commonStyles.text.robotoRegular15,
                          ...{ color: commonStyles.color.firmGrey },
                        }}
                      >
                        Start:
                      </Typography>
                      <Typography
                        style={{
                          ...commonStyles.text.robotoRegular15,
                        }}
                      >
                        {dayjs(item.start).format('YYYY-MM-DD HH:mm')}
                      </Typography>
                    </Grid>
                    <Grid container>
                      <Typography
                        style={{
                          ...commonStyles.text.robotoRegular15,
                          ...{ color: commonStyles.color.firmGrey },
                        }}
                      >
                        End:
                      </Typography>
                      <Typography
                        style={{
                          ...commonStyles.text.robotoRegular15,
                        }}
                      >
                        {dayjs(item.end).format('YYYY-MM-DD HH:mm')}
                      </Typography>
                    </Grid>
                    <Divider style={{ backgroundColor: commonStyles.color.helperText }} />
                  </React.Fragment>
                );
              })
            : null}
        </Grid>
      );
    },
  },
  {
    id: 'action',
    label: 'Action',
    maxWidth: '50px',
    align: 'left',
    componentRender: (props) => {
      return (
        <Grid container direction="column" style={{ padding: '10px' }}>
          <Grid item style={{ padding: '5px' }}>
            <TableButton
              width={'30px'}
              style={{ background: commonStyles.color.black }}
              onClick={() => {
                props.buttonActions.editEventAction(props);
              }}
            >
              <Tooltip title="Edit this event">
                <EditIcon />
              </Tooltip>
            </TableButton>
          </Grid>
          <Grid item style={{ padding: '5px' }}>
            <TableButton
              width={'30px'}
              style={{ background: commonStyles.color.black }}
              onClick={() => {
                props.buttonActions.deleteEventAction(props.id, props.name);
              }}
            >
              <Tooltip title="Delete this event">
                <DeleteIcon />
              </Tooltip>
            </TableButton>
          </Grid>
        </Grid>
      );
    },
  },
];

// const useStyles = makeStyles({
//   root: {
//     width: '100%',
//   },
//   container: {
//     minHeight: '600px',
//   },
// });

const StyledPaper = styled(Paper)({
  width: '100%'
});

const StyledTableContainer = styled(TableContainer)({
  minHeight: '600px'
});

export default function EventsTableComponent({ dataForTable, buttonActions }) {
//  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [dataDows, setDataRows] = useState([]);

  useEffect(() => {
    //console.log('Initial data: ' + JSON.stringify(dataForTable));
    //const venueData = JSON.parse(localStorage.getItem('loggedVenueData'));
    if (dataForTable.length !== 0) {
      setDataRows(dataForTable);
    }
  }, [dataForTable]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <StyledPaper>
      <StyledTableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id} align={column.align} style={{ maxWidth: column.maxWidth }}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {dataDows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                  {columns.map((column, index) => {
                    const value = row[column.id];
                    return (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ maxWidth: column.maxWidth, whiteSpace: 'normal', wordWrap: 'break-word' }}
                      >
                        {column.componentRender
                          ? column.componentRender({
                              ...row,
                              buttonActions,
                              index: index,
                            })
                          : column.format
                          ? column.format(value)
                          : value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </StyledTableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={dataDows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </StyledPaper>
  );
}
