import React, { useState, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import { ThemeProvider, Grid, createTheme, Typography } from '@mui/material';

//import useServerRequest from '../../../hooks/serverRequest';
//import { API_ENDPOINTS_LIST } from '../../../commonUtils/constants';
import { ROUTES_LIST } from '../../../commonUtils/constants';

import EventsTableComponent from './EventsTableComponent';

import commonStyles from '../../../commonUtils/commonStyles';

import Main from '../../../main/Main';

const theme = createTheme({
  palette: {
    secondary: {
      main: commonStyles.color.blue,
    },
    primary: {
      main: commonStyles.color.firmGrey,
    },
    action: {
      disabledBackground: commonStyles.color.blueDisabled,
      disabled: commonStyles.color.white,
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
});

export default function EventsMainComponent({ eventsListData, eventDelete }) {
  const navigate = useNavigate();

  const [state, setState] = useState({
    eventsList: [],
  });

  //const { getRequest: uploadData } = useServerRequest(setGlobalLoading);

  useEffect(() => {
    //console.log('Initial data: ');
    //const venueData = JSON.parse(localStorage.getItem('loggedVenueData'));
    if (eventsListData.length !== 0) {
      //console.log(JSON.stringify(eventsListData));
      setState((prevState) => ({
        ...prevState,
        eventsList: eventsListData,
      }));
    }
  }, [eventsListData]);

  const handleEventDeleteButton = (eventName, eventId) => {
    //ask for delete

    Main.APP_PARAMS.setGlobalState((prevState) => ({
      ...prevState,
      globalDialog: true,
      globalDialogData: {
        title: 'Delete event?',
        message: (
          <div>
            <span style={{ color: 'DimGrey' }}>Are you sure you want to delete event: </span>
            <br />
            <span style={{ color: 'red' }}>{eventName}</span>
            <br />
            <span style={{ color: 'DimGrey' }}> EventID: </span>
            <br />
            <span style={{ color: 'red' }}>{eventId}</span>
            <br />
          </div>
        ),
        buttonOK: 'OK',
        buttonDecline: 'Decline',
      },
      globalDialogHandleClose: () => handleDeleteDialogConfirm(eventId),
    }));
  };

  const handleDeleteDialogConfirm = (eventId) => {
    //console.log('handleDialogConfirm eventId: ' + eventId);
    eventDelete(eventId);

    Main.APP_PARAMS.setGlobalState((prevState) => ({
      ...prevState,
      globalDialog: false,
      globalDialogData: {
        title: 'Title',
        message: 'Message',
        buttonOK: 'OK',
        buttonDecline: 'Decline',
      },
      globalDialogHandleClose: (param) => {},
    }));
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid container direction="row" style={getStyles().topTextGrid}>
        <Grid item xs={12} align="center">
          <Typography
            style={{
              ...commonStyles.text.robotoRegular24,
            }}
          >
            Events List
          </Typography>
        </Grid>

        <Grid item xs={12} align="center">
          <EventsTableComponent
            dataForTable={state.eventsList}
            buttonActions={{
              editEventAction: (item) => {
                //console.log('editEventAction! id: ' + JSON.stringify(item));
                localStorage.setItem('editEventData', JSON.stringify(item));
                navigate(ROUTES_LIST.venueEditEvent);
              },
              deleteEventAction: (eventId, eventName) => {
                //console.log('deleteEventAction! id: ' + eventId + ' name: ' + eventName);
                handleEventDeleteButton(eventName, eventId);
              },
            }}
          />
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}

const getStyles = () => {
  const styles = {
    topTextGrid: {
      marginTop: '70px',
      minHeight: '100px',
      maxWidth: '100%',
      //background: '#FFAA00',
    },
    topTextGridColumn: {
      //background: '#99FF99',
      padding: '30px',
      userSelect: 'none',
    },
    redText: { color: 'red' },
    greenText: { color: 'green' },
  };
  return styles;
};
