var singleton = new (function () {
  /*
    this.publicTestVar = {
      prop1: 'test',
    };
    var privateTestVar = null;
  
    this.init = () => {
      privateTestVar = 'test private';
    };
  
    this.getTestVar = () => {
      return privateTestVar;
    };
  
    this.setTestVar = (val) => {
      privateTestVar = val;
    };
    */
  /*
    this.getfetchBaseURL = () => {
      return 'https://api.etrann-online.com'; //'https://cors-anywhere.herokuapp.com/' + 'http://18.191.31.212:3000';
    };
    */
  this.BASE_URL = process.env.REACT_APP_BASE_URL;
  this.APP_MODE = process.env.REACT_APP_APP_MODE;

  this.APP_PARAMS = {
    userRole: '',
    setGlobalState: (state) => {},
    globalState: {
      toastOpen: false,
      toastAutohideDuration: 15000,
      toastStyle: 'success',
      toastMessage: '',
      globalLoading: false,
      globalDialog: false,
      globalDialogData: {
        title: 'Title',
        message: 'Message',
        buttonOK: 'OK',
        buttonDecline: 'Decline',
      },
      globalDialogHandleClose: (param) => {},
      authToken: '',
    },
  };

  var screenNames = [
    'Home',
    'Privacy policy',
    'EULA',
    'Contact us',
    'Register venue',
    'Add event',
    'Payment',
    'Venue Login',
    'Venue Profile',
    'Welcome!'
  ];

  this.getScreenNames = () => {
    return screenNames;
  };

  this.mainDataObj = {};

  //Init method
  this.init = () => {};
})();

export default singleton;
