import React, { useState, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
//import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
//import { MuiPickersUtilsProvider } from '@material-ui/pickers';
//import MomentUtils from '@date-io/moment';
import './index.css';
import '@fontsource/roboto';
import jwt_decode from 'jwt-decode';
//import reportWebVitals from './reportWebVitals';

//common styles
import commonStyles from './commonUtils/commonStyles';
import { ThemeProvider, createTheme } from '@mui/material/styles';

import Main from './main/Main';
import PrivateRoute from './commonUtils/privateRoute';
import { isValidToken, resetToken } from './commonUtils/commonUtils';

import LoaderComponent from './screens/Components/LoaderComponent/LoaderComponent';
import ToastComponent from './screens/Components/ToastComponent/ToastComponent';
import DialogComponent from './screens/Components/DialogComponent/DialogComponent';

import MaintenanceScreen from './screens/MaintenanceScreen/MaintenanceScreen';
import HomeScreen from './screens/HomeScreen/HomeScreen';
import PrivacyPolicyScreen from './screens/PrivacyPolicyScreen/PrivacyPolicyScreen';
import EndUserLicenseAgreementScreen from './screens/EndUserLicenseAgreementScreen/EndUserLicenseAgreementScreen';
import ContactUsScreen from './screens/ContactUsScreen/ContactUsScreen';
import RegisterVenueScreen from './screens/RegisterVenueScreen/RegisterVenueScreen';
import AddEventScreen from './screens/AddEventScreen/AddEventScreen';
import PaymentScreen from './screens/PaymentScreen/PaymentScreen';
import VenueLoginScreen from './screens/VenueLoginScreen/VenueLoginScreen';
import VenuePersonalDataScreen from './screens/VenuePersonalDataScreen/VenuePersonalDataScreen';
import Welcome from './screens/Welcome/Welcome';
import VenuePushNotificationsScreen from './screens/VenuePushNotificationsScreen/VenuePushNotificationsScreen';
import VenueEventsScreen from './screens/VenueEventsScreen/VenueEventsScreen';
import VenueEditEventScreen from './screens/VenueEditEventScreen/VenueEditEventScreen';
import { ROUTES_LIST } from './commonUtils/constants';

const theme = createTheme({
  palette: {
    primary: {
      // Purple and green play nicely together.
      main: '#00FFFF',
    },
    secondary: {
      // This is green.A700 as hex.
      main: '#11cb5f',
    },
  },
});

const App = () => {
  //require('dotenv').config();

  Main.init();
  document.body.style.background = commonStyles.color.backgroundWhite;
  //Check existing tokens and configure global state

  const existingToken = JSON.parse(localStorage.getItem('token'));

  let checkedToken = '';

  const decoded = existingToken && jwt_decode(existingToken);

  //console.log('existingToken: ' + JSON.stringify(existingToken) + ' : ' + JSON.stringify(decoded));

  //resetToken();
  //localStorage.removeItem('token');

  if (existingToken !== null) {
    checkedToken = isValidToken(decoded.exp) ? existingToken : null;
  } else {
    checkedToken = null;
  }
  //console.log('CheckedToken ' + JSON.stringify(checkedToken));

  const globalStateInit = {
    toastOpen: false,
    toastAutohideDuration: 15000,
    toastStyle: 'success',
    toastMessage: '',
    globalLoading: false,
    globalDialog: false,
    globalDialogData: {
      title: 'Title',
      message: 'Message',
      buttonOK: 'OK',
      buttonDecline: 'Decline',
    },
    globalDialogHandleClose: (param) => {},
    authToken: checkedToken,
  };
  const [globalState, setGlobalState] = useState(globalStateInit);

  const APP_PARAMS = {
    userRole: checkedToken !== null ? 'loggedVenue' : 'user',
    setGlobalState: setGlobalState,
    globalState: globalState,
  };

  Main.APP_PARAMS = APP_PARAMS;
  /*
  useEffect(() => {
    //console.log('check');
    if (checkedToken === null) {
      resetToken(setGlobalState);
    }
  }, [checkedToken]);
  */

  const handleToastClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setGlobalState((prevState) => ({
      ...prevState,
      toastOpen: false,
    }));
  };
  //common routes
  const commonRoutes = (
    <>
      {/*!checkedToken && (
        <Route exact path="/">
          <AuthScreen />
        </Route>
      )*/}
      <Route exact path="/" element={<HomeScreen />} />

      <Route exact path={ROUTES_LIST.home} element={<HomeScreen />} />

      <Route exact path={ROUTES_LIST.privacyPolicy} element={<PrivacyPolicyScreen />} />

      <Route exact path={ROUTES_LIST.eula} element={<EndUserLicenseAgreementScreen />} />

      <Route exact path={ROUTES_LIST.contactUs} element={<ContactUsScreen />} />

      <Route exact path={ROUTES_LIST.registerVenue} element={<RegisterVenueScreen />} />

      <Route exact path={ROUTES_LIST.addEvent} element={<AddEventScreen />} />

      <Route exact path={ROUTES_LIST.payment} element={<PaymentScreen />} />

      <Route exact path={ROUTES_LIST.venueLogin} element={<VenueLoginScreen />} />

      <Route
        exact
        path={ROUTES_LIST.venuePersonalData}
        element={
          <PrivateRoute>
            <VenuePersonalDataScreen />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path={ROUTES_LIST.welcome}
        element={
          <PrivateRoute>
            <Welcome />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path={ROUTES_LIST.venuePushNotifications}
        element={
          <PrivateRoute>
            <VenuePushNotificationsScreen />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path={ROUTES_LIST.venueEvents}
        element={
          <PrivateRoute>
            <VenueEventsScreen />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path={ROUTES_LIST.venueEditEvent}
        element={
          <PrivateRoute>
            <VenueEditEventScreen />
          </PrivateRoute>
        }
      />

      {/*<PrivateRoute exact path={ROUTES_LIST.venuePersonalData}>
        <VenuePersonalDataScreen />
      </PrivateRoute>
      <PrivateRoute exact path={ROUTES_LIST.venuePushNotifications}>
        <VenuePushNotificationsScreen />
      </PrivateRoute>
      <PrivateRoute exact path={ROUTES_LIST.venueEvents}>
        <VenueEventsScreen />
      </PrivateRoute>
      <PrivateRoute exact path={ROUTES_LIST.venueEditEvent}>
        <VenueEditEventScreen />
      </PrivateRoute>*/}
    </>
  );

  const normalMode = (
    <BrowserRouter>
      <Routes>{commonRoutes}</Routes>
    </BrowserRouter>
  );
  const maintenanceMode = <MaintenanceScreen />;

  //console.log('APP_MODE: ' + Main.APP_MODE);

  return (
    <ThemeProvider theme={theme}>
      <LoaderComponent loading={globalState.globalLoading} />
      <DialogComponent dialogOpen={globalState.globalDialog} handleClose={globalState.globalDialogHandleClose} />
      <ToastComponent
        open={globalState.toastOpen}
        close={handleToastClose}
        duration={globalState.toastAutohideDuration}
        styles={globalState.toastStyle}
        message={globalState.toastMessage}
      />

      {Main.APP_MODE === 'normal' ? normalMode : maintenanceMode}
    </ThemeProvider>
  );
};
/*
ReactDOM.render(
  <App />,

  document.getElementById('root')
);
*/
const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
